import { view } from "@risingstack/react-easy-state";

import { translateDayLabel } from "../../common/utils";
import { formatDateTime, formatDeliveryDate } from "../../common/utils/dateUtils";

import cartStore from "../../stores/cart/cartStore";
import deliveryDatesStore from "../../stores/deliveryDates/deliveryDatesStore";

import MiniCartInfoLine from "./MiniCartInfoLine";

const MiniCartDates = () => (
   <div className="tw-w-full">
      <div className="tw-px-1 @[575px]:tw-px-2">
         <MiniCartInfoLine
            margin
            label="Bestillingsfrist"
            value={formatDateTime(deliveryDatesStore.getCurrentDelivery()?.deadline, true, true)}
         />
         <MiniCartInfoLine
            margin
            label={translateDayLabel(cartStore.orderType)}
            value={formatDeliveryDate(
               deliveryDatesStore.getCurrentDelivery()?.date,
               cartStore.orderType,
               deliveryDatesStore.getCurrentDelivery()?.interval
            )}
         />
      </div>
   </div>
);

export default view(MiniCartDates);
