export type LocalazyLanguage = {
   language: string;
   region: string;
   script: string;
   isRtl: boolean;
   localizedName: string;
   name: string;
   pluralType: (n: number) => "zero" | "one" | "two" | "many" | "few" | "other";
};
export type LocalazyFile = {
   cdnHash: string;
   file: string;
   path: string;
   library: string;
   module: string;
   buildType: string;
   productFlavors: string[];
   cdnFiles: { [lang: string]: string };
};
export type LocalazyMetadata = {
   projectUrl: string;
   baseLocale: string;
   languages: LocalazyLanguage[];
   files: LocalazyFile[];
};

const localazyMetadata: LocalazyMetadata = {
   projectUrl: "https://localazy.com/p/tine-handel",
   baseLocale: "nb-NO",
   languages: [
      {
         language: "en",
         region: "US",
         script: "",
         isRtl: false,
         name: "American English",
         localizedName: "American English",
         pluralType: (n) => {
            return n === 1 ? "one" : "other";
         }
      },
      {
         language: "nb",
         region: "NO",
         script: "",
         isRtl: false,
         name: "Norwegian Bokmål (Norway)",
         localizedName: "Norsk bokmål (Norge)",
         pluralType: (n) => {
            return n === 1 ? "one" : "other";
         }
      }
   ],
   files: [
      {
         cdnHash: "ed13368406891cf5fa833d4b09428a6c673a523a",
         file: "translations.json",
         path: "",
         library: "",
         module: "",
         buildType: "",
         productFlavors: [],
         cdnFiles: {
            en_US: "https://delivery.localazy.com/_a765905005502057668266b33190/_e0/ed13368406891cf5fa833d4b09428a6c673a523a/en-US/translations.json",
            nb_NO: "https://delivery.localazy.com/_a765905005502057668266b33190/_e0/ed13368406891cf5fa833d4b09428a6c673a523a/nb-NO/translations.json"
         }
      }
   ]
};

export default localazyMetadata;
