import { view } from "@risingstack/react-easy-state";

import contentStore from "../../stores/cms/contentStore";
import uiStore from "../../stores/uiStore";

import Button from "../Button";
import TranslationText from "../TranslationText";

const LoginButton = () => {
   return (
      <>
         {!contentStore.isPreviewMode && (
            <Button data-testid="login-button" size="regular" className="tw-mr-2" onClick={uiStore.showLoginModal}>
               <TranslationText textKey="Login.LoginButton" defaultText="Logg inn" />
            </Button>
         )}
      </>
   );
};

export default view(LoginButton);
