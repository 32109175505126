// @ts-ignore

// @ts-expect-error
import logoUrl from "url:/src/img/themes/leverant/logo_med_tekst.svg";
// @ts-expect-error
import notfoundJpegLarge from "url:/src/img/themes/leverant/not_found.png?as=jpeg";
// @ts-expect-error
import notfoundWebpLarge from "url:/src/img/themes/leverant/not_found.png?as=webp";
// @ts-expect-error
import notfoundJpegSmall from "url:/src/img/themes/leverant/not_found.png?width=768";
// @ts-expect-error
import notfoundWebpSmall from "url:/src/img/themes/leverant/not_found.png?width=768&as=webp";

import { CustomerFeature, FEATURE_NAME } from "../common/types/featureTypes";
import { Invoice } from "../common/types/invoiceTypes";
import { SupportedLocaleType } from "../common/types/languageTypes";
import { AccessoryType, CustomerType, Product } from "../common/types/productTypes";
import { SiteConfig } from "../common/types/themeTypes";

const CONTENTFUL_ENV = process.env.CONTENTFUL_ENV ?? "";
const ALGOLIA_APPID = "D4VU44A4OA";
const ALGOLIA_SEARCHKEY = "4d6a5d2430cde6d18ba421ceb9bf6d27";
const algoliaEnvironment = process.env.ENV_NAME === "prod" ? "prod" : "preprod";

type ProductEnhancement = {
   customerType?: CustomerType;
   accessoryType?: AccessoryType;
};
/**
 * Parses the response bodies into suitable data structure for use in stores. Diplomis does not up pickup alternatives
 */

const leverantSiteConfig: SiteConfig = {
   storeId: "leverant",
   storeCompanyName: "Leverant",
   siteName: "Leverant Handel",
   orderSupportEmail: "kontat@leverant.no",
   favIcon: "lolipop-icon.svg",
   m3CompanyNumber: "100",
   m3DivisionNumber: "102",
   desktopLogoUrl: logoUrl,
   mobileLogoUrl: logoUrl,
   logoRatio: 0.68,
   productDropdownEnabled: false,
   showComparisonPrice: false,
   faqSlug: "",
   filterAttributes: () => ({ brand: "Type" }),
   filterRefreshOnChange: true,
   tipApiPrefix: "",
   papirflyApsCustomerId: "12843",
   enhanceProduct: (product: Product): Product => {
      return product;
   },
   fetchCustomerFeatures: () => {
      return Promise.resolve([
         { name: FEATURE_NAME.invoicePaymentAvailable, description: "Makes invoice overview available" },
         { name: FEATURE_NAME.createOrderAvailable, description: "Allows customer to place orders" }
      ] satisfies CustomerFeature[]);
   },
   invoiceTooltipText: (invoice: Invoice) => ``,

   deliveryFee: {
      enabled: false
   },
   productAvailability: {
      enabled: false
   },
   newCustomerForm: "https://kundeskjema.diplom-is.no/",
   deliveryNotes: {
      visible: false
   },
   contentImages: {
      cloudinaryMapping: "leverant_images"
   },
   searchConfig: {
      algoliaConfig: {
         appId: ALGOLIA_APPID,
         searchApiKey: ALGOLIA_SEARCHKEY
      },
      searchDomains: [
         {
            type: "product",
            name: "produkter",
            displayName: (locale: SupportedLocaleType) => (locale === "en-US" ? "Products" : "Produkter"),
            homePath: (_locale) => "/produkter.html",
            indexName: `products_${algoliaEnvironment}`,
            linkText: (_locale) => "Se alle produkter"
         },
         {
            type: "v2_article",
            name: "artikler",
            displayName: (locale: SupportedLocaleType) => (locale === "en-US" ? "Articles" : "Artikler"),
            homePath: (_locale) => "/artikler",
            indexName: `contentful_${CONTENTFUL_ENV}`,
            linkText: (_locale) => "Se alle artikler"
         }
      ]
   },
   contactForm: {
      apiPath: "/api/di-contactform",
      reasons: [
         { value: "claims", label: "Reklamasjon", label_en: "Claims" },
         { value: "invoice", label: "Fakturaspørsmål / endring av fakturaadresse", label_en: "Invoice address changes" },
         { value: "delivery", label: "Ønske om levering før oppsatt leveringsdag", label_en: "Questions about ordering" },
         { value: "product_question", label: "Spørsmål om våre produkter eller sortiment", label_en: "Question about products" },
         { value: "other", label: "Annet", label_en: "Other" }
      ]
   },
   whileYouWaitMessage: "Ta frem kaffekoppen, len deg tilbake og nyt soloppgangen!",
   notFound: {
      img: {
         jpeg: {
            small: notfoundJpegSmall,
            large: notfoundJpegLarge
         },
         webp: {
            small: notfoundWebpSmall,
            large: notfoundWebpLarge
         },
         alt: "Kiosken er stengt, hvor finner jeg isen?  Ikke her, du får bare 404."
      }
   },
   notifications: {
      categories: [
         { id: "ORDER_CONFIRMATION", name: "Ordrebekreftelse" },
         { id: "DELIVERY_CONFIRMATION", name: "Leveringsbekreftelse" },
         { id: "ORDER_DEADLINE", name: "Påminnelse om ordrefrist" }
      ],
      enableDeliveryMessages: false
   },
   cart: {
      customerReferenceLabel: "Melding til sjåfør"
   }
};

export default leverantSiteConfig;
