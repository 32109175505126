import { store } from "@risingstack/react-easy-state";
import { addDays } from "date-fns";
import isNil from "lodash-es/isNil";
import remove from "lodash-es/remove";
import { serialize as serialToFormData } from "object-to-formdata";

import { apiClient } from "../../common/apiClient";
import { Claim, ClaimCause, ClaimsResponse, NewClaim } from "../../common/types/claimTypes";
import { M3OrderListed, OrderFilter } from "../../common/types/m3Types";
import { Product } from "../../common/types/productTypes";
import {
   AsyncData,
   initializeWithDefaultData,
   setAsDataAvailable,
   setAsErrorOccured,
   setAsWaitingForData
} from "../../common/utils/asyncDataUtils";

import theme from "../../themes/theme";
import authStore from "../auth/authStore";
import orderStore from "../orders/orderStore";
import { fetchOrderListFromM3 } from "../orders/orderUtilsAndApi";
import { splitClaimResponseIntoClaims } from "./claimsUtils";

const findProductInNewClaimBySku = (querySku: string) =>
   claimsStore.newClaim.data.Products.find((product) => product.ProductItemID === querySku);

const getPayloadFromState = (state: NewClaim, orderNumber: string) => {
   const formPayload = serialToFormData(state, { indices: true });
   formPayload.set("posttoken", process.env.SOLIDUM_POSTTOKEN ?? "Missing Post Token");
   formPayload.set("postform", process.env.SOLIDUM_POSTFORM ?? "Missing Post Form");

   if (isNil(state.image)) {
      formPayload.delete("image");
   }

   formPayload.set("ordernumber", orderNumber);
   formPayload.set("customerNumber", authStore.currentCompany ?? "");

   console.log("Built payload for claims", Object.fromEntries(formPayload.entries()));
   return formPayload;
};

type ClaimsStore = {
   eligibleOrders: AsyncData<M3OrderListed[]>;
   claims: AsyncData<Claim[]>;
   newClaim: AsyncData<NewClaim>;
   limitMonthsBack: number | null;
   limitToApproved: boolean | null;

   fetchEligibleOrders(): Promise<void>;
   fetchClaims(): Promise<void>;
   submitClaim(orderNumber: string): Promise<string | undefined>;
   clearClaims(): void;
   clearNewClaim(): void;
   getCauses(): ClaimCause[];
   updateNewClaim(property: keyof Omit<NewClaim, "Products" | "image">, value: string | undefined | null): void;
   addNewClaimProduct(product: Product, quantity: number, causeCode: string): void;
   removeNewClaimProduct(product: Product): void;
   updateNewClaimFile(file: FileList | null): void;
   updateProductCause(querySku: string, code: string): void;
   updateProductBestBeforeDate(querySku: string, bestBefore: string): void;
   updateProductProductionNumber(querySku: string, productionNumber: string): void;
   updateProductQuantity(querySku: string, amount: number): void;
};

const claimsStore: ClaimsStore = store({
   eligibleOrders: initializeWithDefaultData([]),
   claims: initializeWithDefaultData([]),
   newClaim: initializeWithDefaultData({
      Products: [],
      sender: "",
      email: "",
      telephone: "",
      Notes: "",
      image: null
   }),
   limitMonthsBack: null,
   limitToApproved: null,

   /**
    * Fetches orders for the last 4 days (and a few days into the future, just in case) and
    * filters out those marked with "canSendComplaint".
    */
   clearNewClaim: () => {
      claimsStore.newClaim = initializeWithDefaultData({
         Products: [],
         sender: "",
         email: "",
         telephone: "",
         Notes: "",
         image: null
      });
   },
   fetchEligibleOrders: async () => {
      const filtersNeeded: OrderFilter = {
         orderNumber: "",
         orderName: "",
         status: "",
         dateRange: {
            from: addDays(new Date(), -4),
            to: addDays(new Date(), 30)
         }
      };
      const customerNumber = authStore.currentCompany;
      if (isNil(customerNumber)) {
         return Promise.reject("Need to be logged in to fetch eligible orders for claims");
      }

      setAsWaitingForData(claimsStore.eligibleOrders);

      try {
         const possibleOrders = await fetchOrderListFromM3(theme.m3CompanyNumber, customerNumber, filtersNeeded);
         const eligible: M3OrderListed[] = [];

         for (const candicateOrderDetails of possibleOrders) {
            if (
               "canSendComplaint" in candicateOrderDetails &&
               candicateOrderDetails.canSendComplaint &&
               !candicateOrderDetails.isTemporaryOrder
            ) {
               void orderStore.fetchOrderDetails(candicateOrderDetails.orderNumber);
               eligible.push(candicateOrderDetails);
            }
         }

         setAsDataAvailable(claimsStore.eligibleOrders, eligible);
      } catch (err) {
         setAsErrorOccured(claimsStore.eligibleOrders, "" + err);
      }
   },

   fetchClaims: async () => {
      if (!authStore.isLoggedIn() || isNil(authStore.currentCompany)) {
         return Promise.reject();
      }

      setAsWaitingForData(claimsStore.claims);

      return apiClient(`${process.env.API_HOST}/api/claims/list/${authStore.currentCompany}`, authStore.getSessionToken())
         .get()
         .json()
         .then((res) => {
            const claims: Claim[] = (res as ClaimsResponse[]).map(splitClaimResponseIntoClaims);
            setAsDataAvailable(claimsStore.claims, claims);
         })
         .catch((errorObj) => {
            setAsErrorOccured(claimsStore.claims, "" + errorObj);
         });
   },

   submitClaim: async (orderNumber) => {
      setAsWaitingForData(claimsStore.newClaim);

      const formPayload = getPayloadFromState(claimsStore.newClaim.data, orderNumber);

      // Copies data from store and adds required fields for order number and customer number
      console.log("Sending claim for order number " + orderNumber, formPayload);
      try {
         const response = await apiClient(`${process.env.API_HOST}/api/claims/file`, authStore.getSessionToken())
            .body(formPayload)
            .post()
            .res();
         const data = await response.json();
         claimsStore.clearNewClaim();
         return data?.solidumpost?.postId;
      } catch (error) {
         const message = "Vi greier ikke å sende inn reklamasjonen din.";
         setAsErrorOccured(claimsStore.newClaim, message);
         return undefined;
      }
   },

   clearClaims: () => {
      claimsStore.claims = initializeWithDefaultData([]);
   },

   getCauses: () => [
      { code: "R01", label: "Kort holdbarhetsdato" },
      { code: "R02", label: "Kvalitetsfeil" },
      { code: "R04", label: "Transportskade" },
      { code: "R06", label: "For få produkter levert" },
      { code: "R07", label: "Feil produkt levert" },
      { code: "U08", label: "Feil produkt bestilt" }
   ],

   updateNewClaim: (property, value) => {
      claimsStore.newClaim.data[property] = value ?? "";
   },

   updateNewClaimFile: (fileList) => {
      if (fileList === null) {
         claimsStore.newClaim.data["image"] = null;
      } else if (fileList.length > 0) {
         claimsStore.newClaim.data["image"] = fileList[0];
      }
   },

   updateProductCause: (querySku, code) => {
      const existingProduct = findProductInNewClaimBySku(querySku);

      if (!isNil(existingProduct)) {
         existingProduct.Cause = code;

         switch (code) {
            case "R01":
               existingProduct.BestBeforeDate = existingProduct.BestBeforeDate ?? "";
               delete existingProduct["ProductionNumber"];
               break;

            case "R02":
               existingProduct.BestBeforeDate = existingProduct.BestBeforeDate ?? "";
               existingProduct.ProductionNumber = existingProduct.ProductionNumber ?? "";
               break;

            default:
               delete existingProduct["BestBeforeDate"];
               delete existingProduct["ProductionNumber"];
         }
      }
   },

   updateProductBestBeforeDate: (querySku, bestBefore) => {
      const existingProduct = findProductInNewClaimBySku(querySku);
      if (!isNil(existingProduct)) {
         existingProduct.BestBeforeDate = bestBefore;
      }
   },

   updateProductProductionNumber: (querySku, productionNumber) => {
      const existingProduct = findProductInNewClaimBySku(querySku);
      if (!isNil(existingProduct)) {
         existingProduct.ProductionNumber = productionNumber;
      }
   },

   updateProductQuantity: (querySku, amount) => {
      const existingProduct = findProductInNewClaimBySku(querySku);
      if (!isNil(existingProduct)) {
         existingProduct.ProductQuantity = amount;
      }
   },

   addNewClaimProduct: (product, quantity, causeCode) => {
      claimsStore.newClaim.data.Products.push({
         ProductItemID: product.sku,
         ProductItemName: product.name,
         Cause: causeCode,
         ProductQuantity: quantity
      });
      claimsStore.updateProductCause(product.sku, causeCode);
   },

   removeNewClaimProduct: (product) => {
      remove(claimsStore.newClaim.data.Products, { ProductItemID: product.sku });
   }
});

export default claimsStore;
