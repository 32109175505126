import { Tag } from "@tine/designsystem-ui-react";

import { formatPrice } from "../common/utils";

type DisplayPriceProps = {
   price: number;
   newPrice: number;
   className?: string;
   postPriceDetail?: string;
};

const DisplayPrice = ({ price, newPrice, className, postPriceDetail }: DisplayPriceProps) => {
   // Sometimes the discounted price decimals have different rounding than the full price, so we check if the difference is small
   const isDiscounted = price - newPrice >= 1;

   return (
      <div className={className}>
         {isDiscounted && (
            <div className="tw-text-sm tw-text-muted tw-line-through">
               <strong>{formatPrice(price)}</strong>
            </div>
         )}
         <div className={`tw-font-bold tw-leading-4 ${isDiscounted ? "tw-text-promotion" : ""}`}>
            {formatPrice(newPrice)}
            {postPriceDetail && (
               <Tag
                  bgColor="#fafafa"
                  className="tw-relative tw-top-[-2px] tw-ml-2 tw-inline-block tw-text-xs tw-font-normal tw-text-ink-default"
               >
                  {postPriceDetail}
               </Tag>
            )}
         </div>
      </div>
   );
};

export default DisplayPrice;
