import theme from "../themes/theme";

const FooterDecorationImage = () => {
   return (
      <div className="tw-flex tw-items-end tw-gap-2">
         {theme.footerDecorationImageUrl1 && (
            <img alt={"Temabilde"} width={11.04} height={6.87} src={theme.footerDecorationImageUrl1} />
         )}

         {theme.footerDecorationImageUrl2 && (
            <img alt={"Temabilde"} width={48.73} height={30} src={theme.footerDecorationImageUrl2} />
         )}
      </div>
   );
};

export default FooterDecorationImage;
