import { view } from "@risingstack/react-easy-state";
import { Stack } from "@tine/designsystem-ui-react";
import { cn } from "@tine/designsystem-utils";
import { Link } from "wouter";

import { CategoryInfo } from "../../common/types/categoryTypes";

type MegaMenuColProps = {
   cat: CategoryInfo;
   productsUrl: string;
   columnIndex: number;
   onClick: { (): void };
};

const listItemClassName = cn(["tw-list-none tw-mb-2"]);
const topLevelListItemClassName = cn(["tw-uppercase", "tw-font-bold"]);

const MegaMenuCol = ({ cat, onClick, columnIndex, productsUrl }: MegaMenuColProps) => (
   <Stack className="tw-grow tw-basis-1/3" justify="start" align="start">
      <ul className="tw-text-xs">
         {columnIndex === 0 && (
            <li className={cn(listItemClassName, topLevelListItemClassName)}>
               <Link to={productsUrl} onClick={() => onClick()}>
                  Alle Produkter
               </Link>
            </li>
         )}
         <li className={cn(listItemClassName, topLevelListItemClassName)}>
            <Link to={cat.fullUrl} onClick={() => onClick()}>
               {cat.name}
            </Link>
         </li>
         {cat.children
            .filter((c) => c.visible)
            .map((subcat) => (
               <li className={listItemClassName} key={subcat.id}>
                  <Link to={subcat.fullUrl} key={subcat.url_key} onClick={() => onClick()}>
                     {subcat.name}
                  </Link>
               </li>
            ))}
      </ul>
   </Stack>
);

export default view(MegaMenuCol);
