import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { view } from "@risingstack/react-easy-state";
import { Checkbox, Group, Input, Stack } from "@tine/designsystem-ui-react";
import { keyBy, mapValues, sortBy, toLower, values } from "lodash-es";
import { ChangeEvent, FormEvent, useState } from "react";

import cartStore from "../../stores/cart/cartStore";
import favoriteStore, { FavoriteList } from "../../stores/favoriteStore";
import toastStore from "../../stores/toastStore";

import Button from "../../components/Button";
import ExpandableList from "../../components/ExpandableList";
import Modal from "../../components/ModalWithTracking";

type AddToFavoriteProps = {
   isOpen: boolean;
   onCloseModal: () => void;
   favoriteListAdded: () => void;
};

const textMuted = "tw-text-muted";

const AddToFavoriteModal = ({ isOpen = false, onCloseModal, favoriteListAdded }: AddToFavoriteProps) => {
   const [mode, setMode] = useState<"newList" | "existingList">("existingList");
   const favoriteLists = sortBy(values(favoriteStore.favorites), (l) => toLower(l.listName));

   const onClose = () => {
      onCloseModal();
      setMode("existingList");
   };

   if (mode === "existingList") {
      return (
         <AddToExistingList
            favoriteLists={favoriteLists}
            onClose={onClose}
            onAddedToList={favoriteListAdded}
            setMode={setMode}
            isOpen={isOpen}
         />
      );
   }

   return <AddNewList onClose={onClose} onNewListAdded={favoriteListAdded} isOpen={isOpen} />;
};

type AddNewListProps = {
   onClose: () => void;
   isOpen: boolean;
   onNewListAdded: () => void;
};

const AddNewList = view(({ onClose, isOpen, onNewListAdded }: AddNewListProps) => {
   const cartItems = cartStore.items;
   const [listName, setListName] = useState("");
   const favoriteItems: Record<string, number> = mapValues(keyBy(cartItems, "sku"), "qty");
   const [isValid, setIsValid] = useState(true);
   const skus = cartItems.map(({ sku }) => sku);
   const qtySum = cartItems.reduce((prev, item) => prev + item.qty, 0);

   const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setListName(value);
      setIsValid(value.length > 0 && value.length <= 30);
   };

   const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (listName !== undefined) {
         favoriteStore.createNewListInStorage(listName).then((list) => {
            toastStore.addSuccess("Opprettet", "Favorittlisten har blitt opprettet.");
            if (typeof list === "object") {
               const listId = favoriteStore.getFavoriteListByName(listName)?.id;
               listId && favoriteStore.addMultipleFavorites(skus, list.id, favoriteItems);
            }
            onNewListAdded();
            onClose();
         });
      }
   };

   return (
      <Modal title="Lagre ny favorittliste" isOpen={isOpen} name="Legg til i favorittliste" zIndex={1050}>
         <form onSubmit={handleSubmit}>
            <Input
               type="text"
               label=""
               placeholder="Navn på listen"
               onChange={handleChange}
               description={`${listName.length} /30`}
               showErrorMessage={!isValid}
               errorMessage="Det må være mellom 1 og 30 tegn i navnet"
               maxLength={30}
            />
            <small className={textMuted}>
               Du ønsker å legge til {qtySum} produkter – {skus.length} varelinje(r) – i denne favorittlisten.
            </small>
            <Group justify="end" gap={1} className="tw-mt-6">
               <Button variant="secondary" className="tw-me-2" onClick={onClose}>
                  Avbryt
               </Button>
               <Button
                  variant="primary"
                  trackingEvent="favorite_modal_add_new_list"
                  type="submit"
                  disabled={!isValid || listName.length < 1}
               >
                  Lagre
               </Button>
            </Group>
         </form>
      </Modal>
   );
});

type AddToExistingListProps = {
   favoriteLists: FavoriteList[];
   onClose: () => void;
   onAddedToList: () => void;
   setMode: (newMode: "newList" | "existingList") => void;
   isOpen: boolean;
};

const AddToExistingList = view(({ favoriteLists, onAddedToList, setMode, isOpen, onClose }: AddToExistingListProps) => {
   const cartItems = cartStore.items;
   const skus = cartItems.map(({ sku }) => sku);
   const favoriteItems: Record<string, number> = mapValues(keyBy(cartItems, "sku"), "qty");
   const [checkedLists, setCheckedLists] = useState<string[]>([]);

   const onCheckList = (listId: string) => {
      setCheckedLists([...checkedLists, listId]);
   };
   const onUncheckList = (listId: string) => {
      const newList = checkedLists.filter((id) => id !== listId);
      setCheckedLists(newList);
   };

   const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      checkedLists.map((listId) => {
         favoriteStore.addMultipleFavorites(skus, listId, favoriteItems);
      });
      onAddedToList();
      onClose();
      toastStore.addSuccess("Lagret", "Favorittlisten har blitt oppdatert med nye produkter.");
   };

   return (
      <Modal isOpen={isOpen} onClose={onClose} title="Lagre som favorittliste" name="Legg til i favorittliste" zIndex={1050}>
         <Stack align="center" gap={0} className="tw-mb-6">
            <Button size="regular" variant="tertiary" icon={<PlusCircleIcon />} onClick={() => setMode("newList")}>
               Lag ny liste
            </Button>
            <small className={textMuted}>Opprett en ny liste med de valgte varene</small>
         </Stack>
         {favoriteLists.length > 0 && (
            <div className="tw-px-0 tw-py-2 md:tw-px-3">
               <div className="tw-mb-3">
                  <h2 className="tw-m-0">Legg til i eksisterende liste</h2>
                  <small className={textMuted}>Velg listen du ønsker å legge varene i.</small>
               </div>

               <form onSubmit={handleSubmit}>
                  <Stack align="start">
                     <div className="tw-m-0 tw-overflow-hidden tw-p-0">
                        <ExpandableList
                           cutoff={5}
                           items={favoriteLists.map((list) => (
                              <div key={`add-to-favorite-list-${list.id}`}>
                                 <Checkbox
                                    label={list.listName}
                                    onChange={(event) => (event.target.checked ? onCheckList(list.id) : onUncheckList(list.id))}
                                    isChecked={checkedLists?.includes(list.id)}
                                    className="tw-mb-1"
                                 />
                              </div>
                           ))}
                        />
                     </div>

                     <Group justify="end" align="end" gap={2} className="tw-w-full">
                        <Button
                           variant="primary"
                           trackingEvent={`favorite_modal_add_to_multiple_lists_${checkedLists.length}`}
                           type="submit"
                           disabled={checkedLists.length === 0}
                        >
                           Lagre
                        </Button>
                        <Button variant="secondary" onClick={onClose}>
                           Avbryt
                        </Button>
                     </Group>
                  </Stack>
               </form>
            </div>
         )}
      </Modal>
   );
});

export default view(AddToFavoriteModal);
