import { store } from "@risingstack/react-easy-state";
import find from "lodash-es/find";
import isNil from "lodash-es/isNil";
import isString from "lodash-es/isString";
import uniq from "lodash-es/uniq";

import { apiClient, ensureWretchError } from "../../common/apiClient";
import {
   NotificationCategory,
   NotificationCategoryIdType,
   Recipient,
   RECIPIENT_MODAL,
   RecipientsResponse,
   RecipientValidations
} from "../../common/types/recipientTypes";
import { forceArray } from "../../common/utils";
import {
   AsyncData,
   initializeWithDefaultData,
   setAsDataAvailable,
   setAsErrorOccured,
   setAsWaitingForData
} from "../../common/utils/asyncDataUtils";

import authStore from "../auth/authStore";
import {
   getDeleteRequestData,
   initializeCurrentRecipient,
   initializeRecipientValidations,
   isInvalidRecipient,
   sanitizePhoneNumber,
   toApiRecipient,
   validateRecipient
} from "./recipientsUtils";

export type RecipientsStore = {
   recipients: AsyncData<Recipient[]>;
   categories: AsyncData<NotificationCategory[]>;
   currentRecipient: Recipient;
   requestProgress: AsyncData<boolean>;
   validations: RecipientValidations;
   showModal: RECIPIENT_MODAL;

   fetchRecipients(): Promise<void>;
   addRecipient(): Promise<void>;
   editRecipient(): Promise<void>;
   deleteRecipient(): Promise<void>;
   updateCurrentRecipient(
      property: keyof Omit<Recipient, "profileId" | "allNotifications">,
      value: string | string[] | undefined | null
   ): void;
   updateCurrentNotifications(
      notificationId: string,
      notificationType: keyof Omit<Recipient, "profileId" | "firstName" | "lastName" | "email" | "mobile" | "allNotifications">,
      isEnabled: boolean
   ): void;
   getNotificationCategoryName(queryId: string): string | undefined;
   toggleModal(modalType?: RECIPIENT_MODAL, recipientId?: string): void;
   clearRecipients(): void;
};

const dynamicsRecipientsStore: RecipientsStore = store({
   recipients: initializeWithDefaultData([]),
   categories: initializeWithDefaultData([]),
   currentRecipient: initializeCurrentRecipient(),
   requestProgress: initializeWithDefaultData(false),
   validations: initializeRecipientValidations(),
   showModal: RECIPIENT_MODAL.NONE,

   fetchRecipients: async () => {
      const abortController = setAsWaitingForData(dynamicsRecipientsStore.recipients);
      setAsWaitingForData(dynamicsRecipientsStore.categories);

      if (isNil(authStore.currentCompany)) {
         return Promise.reject();
      }

      return apiClient(
         `${process.env.API_HOST}/api/notification_profiles/${authStore.currentCompany}`,
         authStore.getSessionToken()
      )
         .signal(abortController)
         .get()
         .json()
         .then((res) => {
            const response = res as RecipientsResponse;

            const recipients = response.ProfileList.map(
               (profile) =>
                  ({
                     profileId: profile.Id,
                     firstName: profile.FirstName ?? "",
                     lastName: profile.LastName ?? "",
                     email: profile.Email ?? "",
                     mobile: profile.Mobile ?? "",
                     emailNotifications: profile.EmailNotifications,
                     smsNotifications: profile.SmsNotifications,
                     allNotifications: uniq([...profile.EmailNotifications, ...profile.SmsNotifications])
                  }) satisfies Recipient
            );

            const categories = response.SubscriptionInformation.map(
               (category) =>
                  ({
                     id: category.Id as NotificationCategoryIdType,
                     name: category.NameNo ?? category.Name ?? "Ukjent kategori"
                  }) satisfies NotificationCategory
            );

            setAsDataAvailable(dynamicsRecipientsStore.recipients, recipients);
            setAsDataAvailable(dynamicsRecipientsStore.categories, categories);
         })
         .catch((errorObject) => {
            const wretchError = ensureWretchError(errorObject);
            if (wretchError) {
               console.log("An request failure occurred while fetching recipients: ", wretchError);
            } else {
               console.log("An error occurred while fetching recipients: ", errorObject);
            }

            setAsErrorOccured(dynamicsRecipientsStore.recipients, "" + errorObject);
            setAsErrorOccured(dynamicsRecipientsStore.categories, "" + errorObject);
         });
   },

   addRecipient: async () => {
      dynamicsRecipientsStore.validations = validateRecipient(dynamicsRecipientsStore.currentRecipient);
      if (isInvalidRecipient(dynamicsRecipientsStore.validations)) {
         return;
      }

      const transitionedSuccessfully = setAsWaitingForData(dynamicsRecipientsStore.requestProgress);
      if (!transitionedSuccessfully || isNil(authStore.currentCompany)) {
         return Promise.reject();
      }

      return apiClient(
         `${process.env.API_HOST}/api/notification_profiles/${authStore.currentCompany}`,
         authStore.getSessionToken()
      )
         .json(toApiRecipient(dynamicsRecipientsStore.currentRecipient))
         .post()
         .res((res) => {
            setAsDataAvailable(dynamicsRecipientsStore.requestProgress, res.success);
            void dynamicsRecipientsStore.fetchRecipients();
            dynamicsRecipientsStore.toggleModal(RECIPIENT_MODAL.NONE);
         })
         .catch((errorObject) => {
            setAsErrorOccured(dynamicsRecipientsStore.requestProgress, "" + errorObject);
         });
   },

   editRecipient: async () => {
      dynamicsRecipientsStore.validations = validateRecipient(dynamicsRecipientsStore.currentRecipient);
      if (isInvalidRecipient(dynamicsRecipientsStore.validations)) {
         return;
      }

      const transitionedSuccessfully = setAsWaitingForData(dynamicsRecipientsStore.requestProgress);
      if (!transitionedSuccessfully || isNil(authStore.currentCompany)) {
         return Promise.reject();
      }

      return apiClient(
         `${process.env.API_HOST}/api/notification_profiles/${authStore.currentCompany}`,
         authStore.getSessionToken()
      )
         .json(toApiRecipient(dynamicsRecipientsStore.currentRecipient))
         .put()
         .res((res) => {
            setAsDataAvailable(dynamicsRecipientsStore.requestProgress, res.success);
            void dynamicsRecipientsStore.fetchRecipients();
            dynamicsRecipientsStore.toggleModal(RECIPIENT_MODAL.NONE);
         })
         .catch((errorObject) => {
            setAsErrorOccured(dynamicsRecipientsStore.requestProgress, "" + errorObject);
         });
   },

   deleteRecipient: async () => {
      const transitionedSuccessfully = setAsWaitingForData(dynamicsRecipientsStore.requestProgress);

      if (!transitionedSuccessfully || isNil(authStore.currentCompany)) {
         return Promise.reject();
      }

      return apiClient(
         `${process.env.API_HOST}/api/notification_profiles/${authStore.currentCompany}`,
         authStore.getSessionToken()
      )
         .json(getDeleteRequestData(dynamicsRecipientsStore.currentRecipient))
         .delete()
         .res((res) => {
            setAsDataAvailable(dynamicsRecipientsStore.requestProgress, res.success);
            void dynamicsRecipientsStore.fetchRecipients();
            dynamicsRecipientsStore.toggleModal(RECIPIENT_MODAL.NONE);
         })
         .catch((errorObject) => {
            setAsErrorOccured(dynamicsRecipientsStore.requestProgress, "" + errorObject);
         });
   },

   updateCurrentRecipient: (property, value) => {
      if (isNil(value)) {
         return;
      }

      if (property === "emailNotifications" || property === "smsNotifications") {
         dynamicsRecipientsStore.currentRecipient[property] = forceArray(value) ?? [];
      } else if (isString(value)) {
         if (property === "mobile") {
            console.log("Mobile Phone Sanitization, input: " + value + " => " + sanitizePhoneNumber(value));
            dynamicsRecipientsStore.currentRecipient[property] = sanitizePhoneNumber(value) ?? "";
         } else {
            dynamicsRecipientsStore.currentRecipient[property] = value ?? "";
         }
      }
   },

   updateCurrentNotifications: (
      notificationId: string,
      notificationType: keyof Pick<Recipient, "emailNotifications" | "smsNotifications">,
      isRemove: boolean
   ) => {
      const oldNotificationsList =
         notificationType === "emailNotifications"
            ? dynamicsRecipientsStore.currentRecipient.emailNotifications
            : dynamicsRecipientsStore.currentRecipient.smsNotifications;

      const newNotificationsList = isRemove
         ? oldNotificationsList.filter((oldNotification) => oldNotification !== notificationId)
         : [...oldNotificationsList, notificationId];

      dynamicsRecipientsStore.updateCurrentRecipient(notificationType, newNotificationsList);
   },

   getNotificationCategoryName: (queryId) =>
      find(dynamicsRecipientsStore.categories.data, (category) => category.id === queryId)?.name,

   toggleModal: (modalType: RECIPIENT_MODAL = RECIPIENT_MODAL.NONE, recipientId = "") => {
      if (modalType === RECIPIENT_MODAL.NONE) {
         dynamicsRecipientsStore.currentRecipient = initializeCurrentRecipient();
         dynamicsRecipientsStore.requestProgress = initializeWithDefaultData(false);
         dynamicsRecipientsStore.validations = initializeRecipientValidations();
      }

      if (modalType === RECIPIENT_MODAL.EDIT || modalType === RECIPIENT_MODAL.DELETE) {
         dynamicsRecipientsStore.currentRecipient = dynamicsRecipientsStore.recipients.data.find(
            (recipient) => recipientId === recipient.profileId
         )!;
      }

      dynamicsRecipientsStore.showModal = modalType;
   },

   clearRecipients: () => {
      dynamicsRecipientsStore.recipients = initializeWithDefaultData([]);
      dynamicsRecipientsStore.categories = initializeWithDefaultData([]);
      dynamicsRecipientsStore.currentRecipient = initializeCurrentRecipient();
      dynamicsRecipientsStore.toggleModal();
   }
});

export default dynamicsRecipientsStore;
