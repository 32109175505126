import { SiteConfig } from "../common/types/themeTypes";

import diplomisSiteConfig from "./diplomis";
import leverantSiteConfig from "./leverant";
import tineSiteConfig from "./tine";

const theme: SiteConfig =
   process.env.THEME_NAME === "tine"
      ? tineSiteConfig
      : process.env.THEME_NAME === "diplomis"
        ? diplomisSiteConfig
        : leverantSiteConfig;

export default theme;
