import { cn } from "@tine/designsystem-utils";
import { ElementType, forwardRef, HTMLAttributes } from "react";

type MainColumnProps = HTMLAttributes<HTMLDivElement> & {
   as?: ElementType;
};

const outerCN = cn("tw-flex", "tw-flex-row", "tw-justify-center", "tw-w-full");
const innerCN = cn(
   "tw-flex",
   "tw-flex-row",
   "tw-w-full",
   "tw-max-w-[--main-column-max-width]",
   "tw-px-3 sm:tw-px-4 md:tw-px-12",
   "tw-shrink-0"
);

const MainColumn = forwardRef<HTMLElement, MainColumnProps>(
   ({ children, className = "", as: Component = "div", ...rest }, ref) => (
      <Component ref={ref} className={cn([outerCN, className])} {...rest}>
         <div className={innerCN}>{children}</div>
      </Component>
   )
);

export default MainColumn;
