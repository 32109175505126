import { CategoryInfo } from "../../common/types/categoryTypes";

import MegaMenuCol from "./MegaMenuCol";

type MegaMenuProps = {
   cat: CategoryInfo;
   onHide: () => void;
};

const MegaMenu = ({ cat, onHide }: MegaMenuProps) => (
   <div className="tw-flex tw-w-full tw-flex-row tw-justify-between tw-px-12 tw-pt-4">
      {cat.children.map((subcat, index) => (
         <MegaMenuCol columnIndex={index} key={subcat.id} cat={subcat} productsUrl={cat.fullUrl} onClick={() => onHide()} />
      ))}
   </div>
);

export default MegaMenu;
