import { view } from "@risingstack/react-easy-state";
import { Tooltip } from "@tine/designsystem-ui-react";

import promotionStore from "../stores/promotionStore";

type DiscountLabelProps = {
   sku: string;
};

const DiscountLabel = view(({ sku }: DiscountLabelProps) => {
   const applicablePromotions = promotionStore.getPromotionsBySku(sku);

   return applicablePromotions.map((promotion) => (
      <div key={promotion.id} className="tw-flex">
         <div
            className={`tw-rounded tw-px-2 tw-py-1 tw-text-xxs ${promotion.applicable ? "tw-bg-promotion tw-text-base-0" : "tw-bg-base-100 tw-text-base-600"}`}
         >
            {promotion.banner}
         </div>
         {!promotion.applicable && <Tooltip tooltipText={promotion.inapplicableReason ?? ""} tooltipPosition="top" />}
      </div>
   ));
});

export default DiscountLabel;
