import featuresStore from "../features/featuresStore";
import dynamicsRecipientsStore, { RecipientsStore } from "./dynamicsRecipientsStore";
import legacyRecipientsStore from "./legacyRecipientsStore";

const recipientsStore: RecipientsStore = {
   get recipients() {
      if (featuresStore.useDynamicsForNotificationProfiles) {
         return dynamicsRecipientsStore.recipients;
      } else {
         return legacyRecipientsStore.recipients;
      }
   },

   get categories() {
      if (featuresStore.useDynamicsForNotificationProfiles) {
         return dynamicsRecipientsStore.categories;
      } else {
         return legacyRecipientsStore.categories;
      }
   },

   get currentRecipient() {
      if (featuresStore.useDynamicsForNotificationProfiles) {
         return dynamicsRecipientsStore.currentRecipient;
      } else {
         return legacyRecipientsStore.currentRecipient;
      }
   },

   get requestProgress() {
      if (featuresStore.useDynamicsForNotificationProfiles) {
         return dynamicsRecipientsStore.requestProgress;
      } else {
         return legacyRecipientsStore.requestProgress;
      }
   },

   get validations() {
      if (featuresStore.useDynamicsForNotificationProfiles) {
         return dynamicsRecipientsStore.validations;
      } else {
         return legacyRecipientsStore.validations;
      }
   },

   get showModal() {
      if (featuresStore.useDynamicsForNotificationProfiles) {
         return dynamicsRecipientsStore.showModal;
      } else {
         return legacyRecipientsStore.showModal;
      }
   },

   fetchRecipients: async () => {
      if (featuresStore.useDynamicsForNotificationProfiles) {
         return dynamicsRecipientsStore.fetchRecipients();
      } else {
         return legacyRecipientsStore.fetchRecipients();
      }
   },

   addRecipient: async () => {
      if (featuresStore.useDynamicsForNotificationProfiles) {
         return dynamicsRecipientsStore.addRecipient();
      } else {
         return legacyRecipientsStore.addRecipient();
      }
   },

   editRecipient: async () => {
      if (featuresStore.useDynamicsForNotificationProfiles) {
         return dynamicsRecipientsStore.editRecipient();
      } else {
         return legacyRecipientsStore.editRecipient();
      }
   },

   deleteRecipient: async () => {
      if (featuresStore.useDynamicsForNotificationProfiles) {
         return dynamicsRecipientsStore.deleteRecipient();
      } else {
         return legacyRecipientsStore.deleteRecipient();
      }
   },

   updateCurrentRecipient: (property, value) => {
      if (featuresStore.useDynamicsForNotificationProfiles) {
         return dynamicsRecipientsStore.updateCurrentRecipient(property, value);
      } else {
         return legacyRecipientsStore.updateCurrentRecipient(property, value);
      }
   },

   updateCurrentNotifications: (notificationId, notificationType, isEnabled) => {
      if (featuresStore.useDynamicsForNotificationProfiles) {
         return dynamicsRecipientsStore.updateCurrentNotifications(notificationId, notificationType, isEnabled);
      } else {
         return legacyRecipientsStore.updateCurrentNotifications(notificationId, notificationType, isEnabled);
      }
   },

   getNotificationCategoryName: (queryId) => {
      if (featuresStore.useDynamicsForNotificationProfiles) {
         return dynamicsRecipientsStore.getNotificationCategoryName(queryId);
      } else {
         return legacyRecipientsStore.getNotificationCategoryName(queryId);
      }
   },

   toggleModal: (modalType, recipientId) => {
      if (featuresStore.useDynamicsForNotificationProfiles) {
         return dynamicsRecipientsStore.toggleModal(modalType, recipientId);
      } else {
         return legacyRecipientsStore.toggleModal(modalType, recipientId);
      }
   },

   clearRecipients: () => {
      if (featuresStore.useDynamicsForNotificationProfiles) {
         return dynamicsRecipientsStore.clearRecipients();
      } else {
         return legacyRecipientsStore.clearRecipients();
      }
   }
};

export default recipientsStore;
