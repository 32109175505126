import { store } from "@risingstack/react-easy-state";
import { remove } from "lodash-es";
import defaultTo from "lodash-es/defaultTo";
import find from "lodash-es/find";
import isUndefined from "lodash-es/isUndefined";
import { MouseEventHandler } from "react";
import { v4 as uuid } from "uuid";

import { sendErrorMessage } from "../common/tracking";
import { ToastType } from "../common/types/toastTypes";
import { GAError } from "../common/types/trackingTypes";

type ToastStore = {
   toasts: ToastType[];
   addSuccess(title: string, message: string): void;
   addError(title: string, message: string, gaErrorTracking?: GAError): void;
   hasDuplicate(toast: ToastType): boolean;
   addToast(title: string, message: string, type: "success" | "error", hideDelay?: number, onClick?: MouseEventHandler): void;
};

const toastStore: ToastStore = store({
   toasts: [],

   addSuccess: (title, message) => {
      toastStore.addToast(title, message, "success");
   },

   addError: (title, message, gaErrorTracking) => {
      gaErrorTracking && sendErrorMessage(gaErrorTracking);
      toastStore.addToast(title, message, "error");
   },

   hasDuplicate: (toast) => {
      const search = {
         title: toast.title,
         message: toast.message,
         type: toast.type
      };
      const existing = find(toastStore.toasts, search);
      return !isUndefined(existing);
   },

   addToast: (title, message, type, hideDelay, onClick) => {
      const id = uuid();
      const delay = defaultTo(hideDelay, message.length > 50 ? 5000 : 3000);

      const toast: ToastType = {
         id,
         title,
         message,
         type,
         onClick,
         delay,
         show: false
      };

      // If there is already a toast with this exact title, message and type, we just abort.
      if (toastStore.hasDuplicate(toast)) {
         return;
      }

      toastStore.toasts.push(toast);

      if (toast.delay > 0) {
         // This toast actually cleans up the array 500 ms later, so we don't accumulate hidden Toast elements
         setTimeout(() => {
            remove(toastStore.toasts, { id });
         }, delay + 500);
      }
   }
});

export default toastStore;
