import { capitalize, isArray, mapKeys, uniq } from "lodash-es";
import isEmpty from "lodash-es/isEmpty";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";

import { Recipient, RecipientsResponse, RecipientValidations } from "../../common/types/recipientTypes";

export const initializeCurrentRecipient = (): Recipient => ({
   profileId: "",
   firstName: "",
   lastName: "",
   email: "",
   mobile: "",
   emailNotifications: [],
   smsNotifications: [],
   allNotifications: []
});

export const initializeRecipientValidations = (): RecipientValidations => ({
   firstName: {
      isInvalid: false,
      message: ""
   },
   lastName: {
      isInvalid: false,
      message: ""
   },
   email: {
      isInvalid: false,
      message: ""
   },
   mobile: {
      isInvalid: false,
      message: ""
   },
   notifications: {
      isInvalid: false,
      message: ""
   }
});

export const toApiRecipient = (recipient: Recipient): RecipientsResponse["ProfileList"][0] => {
   return {
      Id: recipient.profileId,
      FirstName: recipient.firstName,
      LastName: recipient.lastName,
      Email: recipient.email,
      Mobile: recipient.mobile,
      EmailNotifications: recipient.emailNotifications,
      SmsNotifications: recipient.smsNotifications
   };
};

export const getDeleteRequestData = (recipient: Recipient) => ({ ProfileId: [recipient.profileId] });

export const validateRecipient = (recipient: Recipient): RecipientValidations => {
   const isInvalidEmail = recipient.email.length > 0 && !isEmail(recipient.email);
   const isInvalidMobile = recipient.mobile.length > 0 && !isMobilePhone(recipient.mobile, "any");

   const noNotifications = isEmpty(recipient.emailNotifications) && isEmpty(recipient.smsNotifications);
   const isInvalidEmailNotifications = !isEmpty(recipient.emailNotifications) && recipient.email.length === 0;
   const isInvalidSmsNotifications = !isEmpty(recipient.smsNotifications) && recipient.mobile.length === 0;

   return {
      firstName: {
         isInvalid: false,
         message: ""
      },
      lastName: {
         isInvalid: false,
         message: ""
      },
      email: {
         isInvalid: isInvalidEmail,
         message: isInvalidEmail ? "Ugyldig epostadresse" : ""
      },
      mobile: {
         isInvalid: isInvalidMobile,
         message: isInvalidMobile ? "Ugyldig telefonnummer" : ""
      },
      notifications: {
         isInvalid: noNotifications || isInvalidEmailNotifications || isInvalidSmsNotifications,
         message: noNotifications
            ? "Du har ikke valgt hva du ønsker å bli varslet om"
            : isInvalidEmailNotifications && isInvalidSmsNotifications
              ? "Du har valgt å bli varslet over epost og sms uten å ha oppgitt gyldig epostadresse og telefonnummer"
              : isInvalidEmailNotifications
                ? "Du har valgt å bli varslet over epost uten å ha oppgitt en gyldig epostadresse"
                : isInvalidSmsNotifications
                  ? "Du har valgt å bli varslet over sms uten å ha oppgitt et gyldig telefonnummer"
                  : ""
      }
   };
};

export const sanitizePhoneNumber = (phone: string) => {
   let sanitized = phone.replaceAll(/[^0-9\\+]+/g, "");
   sanitized = sanitized.replaceAll(/[\\ \\-_]+/g, "");
   if (sanitized.startsWith("00") && sanitized.length > 8) {
      sanitized = "+" + sanitized.substring(2);
   }
   if (sanitized.startsWith("47") && sanitized.length === 10) {
      sanitized = "+" + sanitized;
   }
   if (!sanitized.startsWith("+") && sanitized.length === 8) {
      sanitized = "+47" + sanitized;
   }
   return sanitized;
};

export const isInvalidRecipient = (recipientValidations: RecipientValidations) =>
   recipientValidations.email.isInvalid || recipientValidations.mobile.isInvalid || recipientValidations.notifications.isInvalid;

export type LegacyRecipient = {
   id: string | null;
   name: string;
   email: string;
   mobile: string;
   emailNotifications: string[];
   smsNotifications: string[];
};

export const mapFromLegacyRecipient = (recipient: LegacyRecipient) => {
   const name = recipient.name || "";

   const nameSplit = name.lastIndexOf(" ");
   const firstName = name.substring(0, nameSplit);
   const lastName = name.substring(nameSplit + 1);

   return {
      profileId: recipient.id ?? "",
      firstName,
      lastName,
      email: recipient.email,
      mobile: recipient.mobile,
      emailNotifications: recipient.emailNotifications,
      smsNotifications: recipient.smsNotifications,
      allNotifications: uniq([...recipient.emailNotifications, ...recipient.smsNotifications])
   } satisfies Recipient;
};

export const mapToLegacyRecipient = (recipient: Recipient): LegacyRecipient => ({
   id: !isEmpty(recipient.profileId) ? recipient.profileId : null,
   name: recipient.firstName + " " + recipient.lastName,
   email: recipient.email,
   mobile: recipient.mobile,
   emailNotifications: recipient.emailNotifications,
   smsNotifications: recipient.smsNotifications
});
