import { view } from "@risingstack/react-easy-state";
import { IconCheckCircleFilled, IconPerson } from "@tine/designsystem-icons/sharp";
import some from "lodash-es/some";
import { useLocation } from "wouter";

import authStore from "../stores/auth/authStore";
import loginState from "../stores/auth/loginState";

import Button from "./Button";
import Modal from "./ModalWithTracking";

const pathsToRedirect = {
   "/checkout/confirmation": "/",
   "/customer/favorites/": "/customer/favorites",
   "/customer/subscriptions/": "/customer/subscriptions",
   "/customer/orders/": "/customer/orders"
};

const SwitchCompanyModal = () => {
   const [path, navigate] = useLocation();
   const companies = (authStore.companies || []).filter((company) => company.customerNumber !== authStore.currentCompany);

   const currentCompany = authStore.companies?.find((company) => company.customerNumber === authStore.currentCompany);
   const selectCompany = (customerNumber: string) => {
      if (customerNumber !== authStore.currentCompany) {
         void authStore.changeCompany(customerNumber);

         // Stops iterating when first path is found
         some(pathsToRedirect, (to, startsWithPath) => {
            if (path.startsWith(startsWithPath)) {
               navigate(to);
               return true;
            }
            return false;
         });
      }
   };

   const handleClose = () => {
      if (authStore.currentCompany) {
         loginState.transitionTo("LOGGED_IN");
      } else {
         loginState.transitionTo("NOT_LOGGED_IN");
      }
   };

   const companyItemClass = "tw-py-4 tw-flex tw-flex-row tw-justify-between tw-items-center";

   return (
      <Modal size="lg" title="Velg firma" name="Endre firma" isOpen={loginState.is("COMPANY_SELECTION")} onClose={handleClose}>
         <ul aria-label="Liste over firmaer" className="tw-divide-x-0 tw-divide-y tw-divide-solid tw-divide-ink-subtle tw-p-0">
            {!!currentCompany && (
               <li key={`switch-company-${currentCompany.customerNumber}`} className={companyItemClass}>
                  <div>
                     <div className="tw-font-semibold">{currentCompany?.name}</div>
                     <small>{currentCompany?.customerNumber}</small>
                  </div>
                  <div className="tw-flex">
                     <div className="tw-text-nowrap tw-text-sm tw-font-semibold">Nåværende firma</div>
                     <IconCheckCircleFilled className="tw-text-ink-success" />
                  </div>
               </li>
            )}
            {companies.map((company, index) => (
               <li key={`switch-company-${company.customerNumber}-${index}`} className={companyItemClass}>
                  <div>
                     <div className="tw-font-semibold">{company?.name}</div>
                     <small>{company?.customerNumber}</small>
                  </div>
                  <Button
                     variant="tertiary"
                     className="tw-text-nowrap"
                     icon={<IconPerson />}
                     onClick={() => selectCompany(company.customerNumber)}
                  >
                     Velg firma
                  </Button>
               </li>
            ))}
         </ul>
      </Modal>
   );
};

export default view(SwitchCompanyModal);
