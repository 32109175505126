import { IconClose } from "@tine/designsystem-icons/sharp";
import { Button } from "@tine/designsystem-ui-react";
import { cn } from "@tine/designsystem-utils";
import { ForwardedRef, forwardRef, PropsWithChildren, ReactNode, useEffect, useImperativeHandle, useRef } from "react";
import { createPortal } from "react-dom";

import useOutsideClick from "../../../common/useOutsideClick";
import { focusableElementCssSelector } from "../../../common/utils";

type SlideFromSideProps = PropsWithChildren & {
   renderHeader: () => ReactNode;
   renderFooter?: () => ReactNode;
   show: boolean;
   side?: "left" | "right";
   onHide: () => void;
};

const commonBorderClassName = cn("tw-border-0 tw-border-solid print:tw-hidden");
const rightBorderClassName = cn("tw-border-r tw-border-r-ink-border-subtle", commonBorderClassName);

const leftBorderClassName = cn("tw-border-l tw-border-l-ink-border-subtle", commonBorderClassName);

const commonClassName = cn(
   "tw-fixed tw-bottom-0 tw-top-0 tw-z-20",
   "tw-transition tw-duration-300 tw-ease-in-out",
   "tw-z-50 tw-bg-base-0",
   "tw-flex tw-flex-col"
);

const slideFromSideClassName = {
   left: cn(commonClassName, "tw-left-0", rightBorderClassName),
   right: cn(commonClassName, "tw-right-0", leftBorderClassName)
};

const visibleClassName = cn("tw-translate-x-0");

const invisibleClassName = { left: cn("-tw-translate-x-full"), right: cn("tw-translate-x-full") };

const headerContainerClassName = cn("tw-flex", "tw-justify-between", "tw-items-center", "tw-m-3");
const bodyContainerClass = cn("tw-m-3", "tw-overflow-y-scroll", "tw-grow", "tw-relative");

const SlideFromSideContainer = forwardRef(
   (
      { show, onHide, renderHeader, renderFooter, side = "left", children }: SlideFromSideProps,
      forwardedRef: ForwardedRef<HTMLDivElement>
   ) => {
      const ref = useRef<HTMLDivElement>(null);
      useOutsideClick([ref], () => {
         onHide();
      });

      useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(forwardedRef, () => ref.current);

      useEffect(() => {
         const bodyElement = document.body;
         const bodyClasses = ["tw-fixed", "tw-overflow-hidden", "tw-top-0", "tw-bottom-0", "tw-left-0", "tw-right-0"];
         if (show) {
            bodyElement.classList.add(...bodyClasses);
         } else {
            bodyElement.classList.remove(...bodyClasses);
         }
      }, [show]);

      useEffect(() => {
         const keydownHandler = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
               onHide();
            }
         };
         if (show) {
            document.addEventListener("keydown", keydownHandler);
         } else {
            document.removeEventListener("keydown", keydownHandler);
         }
         return () => removeEventListener("keydown", keydownHandler);
      }, [show]);

      useEffect(() => {
         if (show && ref && typeof ref !== "function") {
            const firstFocusable: HTMLElement = ref.current?.querySelector(focusableElementCssSelector) as HTMLElement;
            firstFocusable && firstFocusable.focus();
         }
      }, [show, ref]);

      const className = cn(slideFromSideClassName[side], show ? visibleClassName : invisibleClassName[side]);
      return (
         <div className={className} ref={ref}>
            <div className={headerContainerClassName}>
               <div>{renderHeader()}</div>
               <div>
                  <Button variant="tertiary" icon={<IconClose />} onClick={() => onHide()}></Button>
               </div>
            </div>
            <div className={bodyContainerClass}>{children}</div>
            {renderFooter ? renderFooter() : null}
         </div>
      );
   }
);
const backdropClassName = cn("tw-fixed tw-inset-0 tw-z-40 tw-bg-base-900 tw-opacity-50");

const Backdrop = () => {
   return <div className={backdropClassName} />;
};

const SlideFromSide = forwardRef(({ children, show, ...props }: SlideFromSideProps, ref: ForwardedRef<HTMLDivElement>) => {
   return (
      <>
         {createPortal(
            <>
               {show && <Backdrop />}
               <SlideFromSideContainer show={show} {...props}>
                  {children}
               </SlideFromSideContainer>
            </>,
            document.body
         )}
      </>
   );
});

export default SlideFromSide;
