import { view } from "@risingstack/react-easy-state";
import { cn } from "@tine/designsystem-utils";
import { chunk } from "lodash-es";
import { useState } from "react";

import Button from "./Button";

type ExpandableListProps = {
   items: React.ReactElement[];
   cutoff?: number;
};

const ExpandableList = ({ items, cutoff = 3 }: ExpandableListProps) => {
   const [lastVisibleListIndex, setLastVisibleListIndex] = useState(0);
   const chunkList = chunk(items, cutoff);
   const chunkStyle = "tw-transition-[max-height] tw-duration-500 tw-overflow-hidden";
   return (
      <div>
         {chunkList.map((list, index) => {
            const heightStyle = index <= lastVisibleListIndex ? "tw-max-h-[250px]" : "tw-max-h-0";
            return (
               <div key={`favoritelists-list-${index}`}>
                  <div key={`favoritelists-list-data-${index}`} className={cn(chunkStyle, heightStyle)}>
                     <div id={`visible-list-${index}`} className="tw-flex tw-flex-col">
                        {index <= lastVisibleListIndex && list.map((element) => element)}
                     </div>
                  </div>
                  {index - 1 === lastVisibleListIndex && (
                     <Button
                        variant="tertiary"
                        className="tw-my-2 tw-size-fit"
                        onClick={() => setLastVisibleListIndex(lastVisibleListIndex + 1)}
                        aria-controls={`visible-list-${index}`}
                     >
                        Vis flere
                     </Button>
                  )}
               </div>
            );
         })}
      </div>
   );
};

export default view(ExpandableList);
