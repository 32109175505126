import { cn } from "@tine/designsystem-utils";

import { DayFlagLabel } from "../../../common/types/calendarTypes";

type CalendarKeysContext = "default" | "delivery-date-picker";

type Size = "compact" | "regular";

export type CalendarKeysProps = {
   flagLabels: DayFlagLabel[];
   context?: CalendarKeysContext;
   size?: Size;
};

const CalendarKey = ({ flag, label, size }: DayFlagLabel & { size: Size }) => {
   const keyClassName = cn("tw-border-solid tw-border-2 tw-rounded-button tw-leading-none", {
      "tw-px-button-compact-padding-x tw-py-button-compact-padding-y tw-w-7 tw-h-7": size === "compact",
      "tw-px-button-regular-padding-x tw-py-button-regular-padding-y tw-w-8 tw-h-8": size === "regular",
      "tw-bg-button-primary-bg tw-text-button-primary-text tw-border-button-primary-bg": flag === "selected",
      "tw-bg-button-secondary-bg tw-text-button-secondary-text tw-border-button-secondary-border": flag === "secondary",
      "tw-bg-calendar-cancelled-bg tw-text-calendar-cancelled-text tw-border-calendar-cancelled-bg": flag === "cancelled"
   });
   return (
      <div className={cn("tw-flex tw-flex-grow-0 tw-gap-1 tw-items-center", { "tw-text-sm": size === "compact" })}>
         <div className={keyClassName} />
         {label}
      </div>
   );
};

const CalendarKeys = ({ flagLabels, size = "compact" }: CalendarKeysProps) => {
   return (
      <>
         {flagLabels.map(({ flag, label }) => (
            <CalendarKey key={flag} flag={flag} label={label} size={size} />
         ))}
      </>
   );
};

export default CalendarKeys;
