import { view } from "@risingstack/react-easy-state";
import { cn } from "@tine/designsystem-utils";

import { CmsContentSearchResults, SEARCH_TYPE, SearchComponentProps } from "../../common/types/searchTypes";
import useSupportedLocale from "../../common/utils/useSupportedLocale";

import searchStore from "../../stores/searchStore";

import { InternalLink } from "../Links";
import CmsContentHit from "./CmsContentHit";

const hide = () => searchStore.clear(SEARCH_TYPE.QuickSearch, true);

const HitsForCmsContent = ({ searchDomain, maxHits = Number.MAX_VALUE, isNarrow = false }: SearchComponentProps) => {
   const results: CmsContentSearchResults | undefined = searchStore[SEARCH_TYPE.QuickSearch].results.find(
      (result) => result.name === searchDomain.name
   ) as CmsContentSearchResults | undefined;
   const hits = (results?.hits || []).slice(0, maxHits);
   const locale = useSupportedLocale();
   const containerCN = cn("tw-grid tw-gap-y-3", {
      "lg:tw-grid-cols-3 lg:tw-gap-x-8": !isNarrow
   });

   if (results && hits.length) {
      const { queryID } = results;
      return (
         <>
            <div>
               <h3 className="tw-pb-2">{searchDomain.displayName(locale)}</h3>
            </div>
            <div className={containerCN}>
               {hits.map((hit, i) => (
                  <CmsContentHit
                     key={hit.objectID}
                     hit={hit}
                     position={i + 1}
                     locale={locale}
                     searchDomain={searchDomain}
                     queryID={queryID}
                  />
               ))}
            </div>
            <div>
               <InternalLink
                  className="tw-my-2 tw-inline-block tw-p-2"
                  onClick={() => hide()}
                  href={`/search/${searchDomain.name}?q=${results.query}`}
               >{`${searchDomain.linkText(locale)} med '${results.query}'`}</InternalLink>
            </div>
         </>
      );
   }
   return null;
};

export default view(HitsForCmsContent);
