import { IconDownload, IconOpenInNew } from "@tine/designsystem-icons/sharp";
import { Link, LinkProps } from "@tine/designsystem-ui-react";
import { PropsWithChildren } from "react";
import { Link as WouterLink } from "wouter";

export const InternalLink = ({
   href = "",
   variant = "default",
   children,
   icon = null,
   iconPosition = "left",
   className = "",
   ...props
}: PropsWithChildren<LinkProps>) => {
   return (
      <WouterLink href={href} {...props} asChild>
         <Link
            as="a"
            className={className}
            variant={variant}
            icon={icon}
            iconPosition={iconPosition}
            aria-label={"Gå til " + href}
         >
            {children}
         </Link>
      </WouterLink>
   );
};

export const ExternalLink = ({
   href = "",
   variant = "default",
   children,
   className = "",
   ...props
}: PropsWithChildren<LinkProps>) => {
   return (
      <Link
         href={href}
         as="a"
         target="_blank"
         rel="noopener noreferrer"
         icon={<IconOpenInNew />}
         className={className}
         variant={variant}
         iconPosition="right"
         aria-label={"Gå til " + href}
         {...props}
      >
         {children}
      </Link>
   );
};

export const DownloadLink = ({
   href = "",
   variant = "default",
   children,
   className = "",
   ...props
}: PropsWithChildren<LinkProps>) => {
   return (
      <Link
         href={href}
         target="_blank"
         rel="noopener noreferrer"
         className={className}
         variant={variant}
         icon={<IconDownload />}
         iconPosition="right"
         aria-label={"Last ned " + href}
         {...props}
      >
         {children}
      </Link>
   );
};
