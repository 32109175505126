import { cn } from "@tine/designsystem-utils";
import { CSSProperties, forwardRef, HTMLAttributes } from "react";

import { PopoverOrientation } from "../Popover";

type ArrowProps = {
   style: CSSProperties;
   orientation?: PopoverOrientation;
} & HTMLAttributes<HTMLDivElement>;

type VerticalOrHorizontal = "top-bottom" | "left-right";

type ClassesByOrientation = { [key in PopoverOrientation]: string };

type ClassesByVerticalOrHorizontal = { [key in VerticalOrHorizontal]: string };

const classesByOrientation: ClassesByOrientation = {
   top: [
      "tw-bottom-[--popover-arrow-displacement]",
      "before:tw-bottom-0",
      "before:tw-border-t-[line-width:--popover-arrow-height]",
      "before:tw-border-b-transparent",
      "after:tw-bottom-[--popover-border-width]",
      "after:tw-border-t-[line-width:--popover-arrow-height]",
      "after:tw-border-b-transparent"
   ].join(" "),
   bottom: [
      "tw-top-[--popover-arrow-displacement]",
      "before:tw-top-0",
      "before:tw-border-b-[line-width:--popover-arrow-height]",
      "before:tw-border-t-transparent",
      "after:tw-top-[--popover-border-width]",
      "after:tw-border-b-[line-width:--popover-arrow-height]",
      "after:tw-border-t-transparent"
   ].join(" "),
   left: [
      "tw-right-[--popover-arrow-displacement]",
      "before:tw-right-0",
      "before:tw-border-l-[line-width:--popover-arrow-height]",
      "before:tw-border-r-transparent",
      "after:tw-right-[--popover-border-width]",
      "after:tw-border-l-[line-width:--popover-arrow-height]",
      "after:tw-border-r-transparent"
   ].join(" "),
   right: [
      "tw-left-[--popover-arrow-displacement]",
      "before:tw-left-0",
      "before:tw-border-r-[line-width:--popover-arrow-height]",
      "before:tw-border-l-transparent",
      "after:tw-left-[--popover-border-width]",
      "after:tw-border-r-[line-width:--popover-arrow-height]",
      "after:tw-border-l-transparent"
   ].join(" ")
};

const classesByVerticalOrHorizontal: ClassesByVerticalOrHorizontal = {
   "top-bottom": [
      "tw-w-[--popover-arrow-width] tw-h-[--popover-arrow-height]",
      "before:tw-border-x-transparent before:tw-border-x-[line-width:--popover-arrow-height]",
      "after:tw-border-x-transparent",
      "after:tw-border-x-[line-width:--popover-arrow-height]"
   ].join(" "),
   "left-right": [
      "tw-h-[--popover-arrow-width] tw-w-[--popover-arrow-height]",
      "before:tw-border-y-transparent before:tw-border-y-[line-width:--popover-arrow-height]",
      "after:tw-border-y-transparent",
      "after:tw-border-y-[line-width:--popover-arrow-height]"
   ].join(" ")
};

const commonClasses = cn([
   "tw-z-10",
   "before:tw-absolute before:tw-content-[''] after:tw-absolute after:tw-content-['']",
   "before:tw-border-solid before:tw-border-ink-border-subtle",
   "after:tw-border-solid after:tw-border-[--popover-bg]"
]);

const getClassName = (orientation: PopoverOrientation) => {
   const verticalOrHorizontal = orientation === "top" || orientation === "bottom" ? "top-bottom" : "left-right";
   return [classesByOrientation[orientation], classesByVerticalOrHorizontal[verticalOrHorizontal], commonClasses].join(" ");
};

const Arrow = forwardRef<HTMLDivElement, ArrowProps>(({ style, orientation = "bottom" }, ref) => {
   return <div className={getClassName(orientation)} ref={ref} style={style}></div>;
});

export default Arrow;
