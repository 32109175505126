import { isSameDay } from "date-fns";
import { useEffect, useState } from "react";

import { CalendarInputMode, DayFlags, MonthAndYear, MonthNumber } from "../../../common/types/calendarTypes";
import { getFirstDate, isDateBetween } from "../../../common/utils/dateUtils";

import Months from "./Months";

export type CalendarProps = {
   dates: Date[];
   onDatesChange: (dates: Date[]) => void;
   mode?: CalendarInputMode;
   getDayFlags?: (date: Date) => DayFlags;
   monthCount?: 1 | 2 | 3 | 4;
   month?: MonthNumber;
   year?: number;
   showWeekNumber?: boolean;
   currentMonthOnly?: boolean;
   weekStartsOn?: Day;
   isArrowFocus?: boolean;
   size?: "compact" | "regular";
   min?: Date;
   max?: Date;
};

const defaultDayFlags = (_date: Date): DayFlags => {
   return {
      selectable: true
   };
};

const dateCompare = (a: Date, b: Date) => a.getTime() - b.getTime();

const getMonthAndYear = (dates: Date[]): MonthAndYear => {
   const monthAndYearDate = getFirstDate(dates) || new Date();
   return {
      month: monthAndYearDate.getMonth() as MonthNumber,
      year: monthAndYearDate.getFullYear()
   };
};

const Calendar = ({
   dates,
   getDayFlags = defaultDayFlags,
   onDatesChange,
   monthCount = 1,
   currentMonthOnly,
   showWeekNumber,
   mode = "single",
   size = "compact",
   min,
   max,
   ...props
}: CalendarProps) => {
   const monthAndYear = getMonthAndYear(dates);
   const { month: initialMonth = monthAndYear.month } = props;
   const { year: initialYear = monthAndYear.year } = props;

   const [month, setMonth] = useState<MonthNumber>(initialMonth);
   const [year, setYear] = useState<number>(initialYear);

   useEffect(() => {
      if (mode === "range" && dates.length > 1) {
         const monthAndYear = getMonthAndYear(dates);
         const { month = monthAndYear.month } = props;
         const { year = monthAndYear.year } = props;
         setMonth(month);
         setYear(year);
      }
   }, [props.month, props.year, dates]);

   const getDayFlagsByMode = {
      single: (date: Date) => ({ ...getDayFlags(date), selected: !!dates.length && isSameDay(date, dates[0]) }),
      multi: (date: Date) => ({ ...getDayFlags(date), selected: dates.some((d) => isSameDay(d, date)) }),
      range: (date: Date) => ({
         ...getDayFlags(date),
         selected: dates.some((d) => isSameDay(d, date)),
         inRange: dates.length === 2 && isDateBetween(date, dates[0], dates[1])
      })
   };

   const handleClickByMode = {
      single: (date: Date) => onDatesChange([date]),
      multi: (date: Date) => {
         if (dates.some((d) => isSameDay(d, date))) {
            onDatesChange(dates.filter((d) => !isSameDay(d, date)).sort());
         } else {
            onDatesChange([...dates, date].sort());
         }
      },
      range: (date: Date) => {
         if (dates.length === 2 && !dates.some((d) => isSameDay(d, date))) {
            onDatesChange([date]);
            return;
         }
         if (dates.some((d) => isSameDay(d, date))) {
            onDatesChange(dates.filter((d) => !isSameDay(date, d)).sort(dateCompare));
         } else {
            onDatesChange([...dates, date].sort(dateCompare));
         }
      }
   };

   const selecting = (mode === "range" && dates.length === 1 && dates[0]) || undefined;
   return (
      <Months
         {...props}
         data-testid="delivery-calendar"
         selecting={selecting}
         getDayFlags={getDayFlagsByMode[mode]}
         monthCount={monthCount}
         month={month}
         year={year}
         onDayClicked={handleClickByMode[mode]}
         currentMonthOnly={currentMonthOnly}
         showWeekNumber={showWeekNumber}
         size={size}
         min={min}
         max={max}
      />
   );
};

export default Calendar;
