import { view } from "@risingstack/react-easy-state";
import { cn } from "@tine/designsystem-utils";
import { useLocation } from "wouter";

import { ClassNameResolver } from "../common/types/commonTypes";
import { FEATURE_NAME } from "../common/types/featureTypes";

import authStore from "../stores/auth/authStore";
import loginState from "../stores/auth/loginState";
import categoryStore from "../stores/categories/categoryStore";
import featuresStore from "../stores/features/featuresStore";

import NotificationsBadge from "../account/notifications/NotificationsBadge";
import NavLink from "../components/NavLink";

type MenuType = "account" | "user" | "mobile";
type MenuItemsProps = {
   type: MenuType;
};

export const resolveCN: ClassNameResolver<MenuType, "item"> = (type, name = "item") => {
   const classNames = {
      item: cn({
         "tw-px-4 tw-py-3": type === "user",
         "tw-px-0 tw-py-2 first:tw-pt-0": type === "account",
         "tw-px-0 tw-py-1 first:tw-pt-0 last:tw-pb-0": type === "mobile"
      })
   };
   return classNames[name];
};

const MenuItems = ({ type }: MenuItemsProps) => {
   const [_, redirectTo] = useLocation();
   const hideMenu = () => {
      categoryStore.showMenu(false);
   };
   const logout = () => {
      if (authStore.isPunchoutSession()) {
         loginState.transitionTo("NOT_LOGGED_IN");
         window.location.replace("/");
      } else {
         redirectTo("/");
         loginState.transitionTo("NOT_LOGGED_IN");
      }
      hideMenu();
   };

   return (
      <>
         {!authStore.isPunchoutSession() && (
            <li className={resolveCN(type)}>
               <NavLink onClick={hideMenu} to="/customer/account">
                  Brukere og innlogging
               </NavLink>
            </li>
         )}
         <li className={resolveCN(type)}>
            <NavLink onClick={hideMenu} to="/customer/promotions">
               Kampanjer
            </NavLink>
         </li>
         <li className={resolveCN(type)}>
            <NavLink onClick={hideMenu} to={"/customer/deliveries"}>
               Leveringsdager
            </NavLink>
         </li>
         <li className={resolveCN(type)}>
            <NavLink onClick={hideMenu} to="/customer/orders">
               Ordrer
            </NavLink>
         </li>
         <li className={resolveCN(type)}>
            <NavLink onClick={hideMenu} to="/customer/favorites">
               Favorittlister
            </NavLink>
         </li>
         {featuresStore.hasCustomerFeature(FEATURE_NAME.subscriptionOrderAvailable) && !authStore.isPunchoutSession() && (
            <li className={resolveCN(type)}>
               <NavLink onClick={hideMenu} to={"/customer/subscriptions"}>
                  Abonnement
               </NavLink>
            </li>
         )}
         {featuresStore.hasCustomerFeature(FEATURE_NAME.invoicePaymentAvailable) && (
            <li className={resolveCN(type)}>
               <NavLink onClick={hideMenu} to={"/customer/invoices"}>
                  Fakturaer
               </NavLink>
            </li>
         )}
         {featuresStore.contractualRecommendations && (
            <li className={resolveCN(type)}>
               <NavLink onClick={hideMenu} to="/customer/contractual">
                  Avtalt sortiment
               </NavLink>
            </li>
         )}
         {featuresStore.claimsEnabled && (
            <li className={resolveCN(type)}>
               <NavLink onClick={hideMenu} to="/customer/claims">
                  Reklamasjoner
               </NavLink>
            </li>
         )}
         {featuresStore.notificationsEnabled && (
            <li className={cn(resolveCN(type), "tw-flex")}>
               <NavLink onClick={hideMenu} to="/customer/notifications">
                  Varslinger
               </NavLink>
               <NotificationsBadge />
            </li>
         )}
         {featuresStore.keyFiguresAvailable && (
            <li className={cn(resolveCN(type), "tw-flex")}>
               <NavLink onClick={hideMenu} to={"/customer/keyfigures"}>
                  Nøkkeltall
               </NavLink>
            </li>
         )}
         <li className={cn(resolveCN(type), "tw-flex")}>
            <NavLink onClick={hideMenu} to={"/customer/companyInfo"}>
               Bedriftsinformasjon
            </NavLink>
         </li>
         {type === "mobile" && authStore.isLoggedIn() && <NavLink onClick={logout}>Logg ut</NavLink>}
      </>
   );
};

export default view(MenuItems);
