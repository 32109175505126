import { view } from "@risingstack/react-easy-state";
import { useRef } from "react";
import { Link } from "wouter";

import { productImageUrl } from "../../common/imageUtils";
import { sendProductClick } from "../../common/tracking";
import { Product, ProductTileCallbacks } from "../../common/types/productTypes";
import { AlgoliaProductHit, SEARCH_TYPE } from "../../common/types/searchTypes";
import { GAListInformation, LIST_TYPE } from "../../common/types/trackingTypes";

import authStore from "../../stores/auth/authStore";
import productStore from "../../stores/product/productStore";
import searchStore from "../../stores/searchStore";

import AddToCartButton from "../AddToCartButton";

type ProductHitProps = {
   hit: AlgoliaProductHit;
   product?: Product;
   position: number;
   algoliaIndex: string;
   queryID: string;
   numberOfHits: number;
};
const hide = () => searchStore.clear(SEARCH_TYPE.QuickSearch, true);

const ProductHit = ({ hit, position, algoliaIndex, queryID, product, numberOfHits }: ProductHitProps) => {
   const imageRef = useRef<HTMLImageElement>(null);
   const productUrl = productStore.getUrl(hit);

   // Callbacks for Algolia event reporting (Personalization, A/B testing etc relies on this)
   const callbacks = {
      addToCart: (product) => {
         searchStore.reportAlgoliaAddToCart(product.sku, algoliaIndex, queryID, true, SEARCH_TYPE.QuickSearch);
      },
      click: (product, _listName, position) => {
         searchStore.reportAlgoliaClick(product.sku, algoliaIndex, queryID, position, true, SEARCH_TYPE.QuickSearch);
      }
   } satisfies ProductTileCallbacks;

   const hitClicked = () => {
      searchStore.reportAlgoliaClick(hit.sku, algoliaIndex, queryID, position, true, SEARCH_TYPE.QuickSearch);
      if (product) {
         sendProductClick(product, { type: LIST_TYPE.SEARCH, name: "Quick search", length: numberOfHits }, position);
      }
      hide();
   };

   return (
      <div className="tw-flex tw-items-center tw-justify-between tw-px-1 tw-py-2 hover:tw-bg-[--search-quick-results-item-bg-hover] lg:tw-pr-4">
         <Link onClick={hitClicked} to={productUrl} className="tw-flex tw-items-center">
            <img
               alt={`Produktbilde av ${hit.name}`}
               loading="lazy"
               width={48}
               src={productImageUrl(hit.sku, 48)}
               className="tw-p-0"
               ref={imageRef}
            />
            <div className="tw-mx-2 tw-basis-full">{hit.name}</div>
         </Link>
         {authStore.isLoggedIn() && product && !product.newSku && (
            <div className="tw-w-14 tw-shrink-0 tw-grow-0">
               <AddToCartButton
                  product={product}
                  qty={1}
                  listInformation={
                     {
                        type: LIST_TYPE.SEARCH,
                        name: "Quick search",
                        length: numberOfHits
                     } satisfies GAListInformation
                  }
                  callbacks={callbacks}
                  position={position}
                  imageRefFn={() => imageRef}
                  className="lg:ml-2"
               />
            </div>
         )}
      </div>
   );
};

export default view(ProductHit);
