import Bugsnag from "@bugsnag/js";
import { view } from "@risingstack/react-easy-state";
import React, { Suspense, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Redirect, Route, Switch, useLocation } from "wouter";

import "@contentful/live-preview/style.css";
import "react-loading-skeleton/dist/skeleton.css";

import { cn } from "@tine/designsystem-utils";

import { ensureError, TokenExpiryError } from "./common/errors";
import useMediaQuery from "./common/useMediaQuery";
import { desktopBreakpoint } from "./common/utils";
import MainColumn from "./components/common/MainColumn";

import authStore from "./stores/auth/authStore";
import loginState from "./stores/auth/loginState";
import barcodeStore from "./stores/barcodeStore";
import categoryStore from "./stores/categories/categoryStore";
import contentStore from "./stores/cms/contentStore";
import deliveryDatesStore from "./stores/deliveryDates/deliveryDatesStore";
import featuresStore from "./stores/features/featuresStore";
import productStore from "./stores/product/productStore";
import uiStore from "./stores/uiStore";

import ChangeDeliveryDateModal from "./components/ChangeDeliveryDateModal";
import ExpiredDeadlineModal from "./components/ExpiredDeadlineModal";
import FlyingProduct from "./components/FlyingProduct";
import Loading from "./components/Loading";
import ForcedLogoutModal from "./components/login/ForcedLogoutModal";
import LoginModal from "./components/login/LoginModal";
import PreprodInfo from "./components/PreprodInfo";
import ScrollToTop from "./components/ScrollToTop";
import SessionChecker from "./components/SessionChecker";
import SwitchCompanyModal from "./components/SwitchCompanyModal";
import ToastDisplay from "./components/ToastDisplay";
import TopBarWarnings from "./components/TopBarWarnings";
import VurbisPunchoutScript from "./components/VurbisPunchoutScript";
import Footer from "./footer/Footer";
import DesktopHeader from "./header/desktop/DesktopHeader";
import LoginProgress from "./header/LoginProgress";
import MobileHeader from "./header/mobile/MobileHeader";
import theme from "./themes/theme";

const BarcodeScanner = React.lazy(() => import("./components/barcodes/BarcodeScanner"));
const Cart = React.lazy(() => import("./pages/cart/Cart"));
const CustomerRoutes = React.lazy(() => import("./account/CustomerRoutes"));
const ProductListPage = React.lazy(() => import("./pages/ProductListPage"));
const Home = React.lazy(() => import("./pages/Home"));
const SearchResult = React.lazy(() => import("./pages/SearchResult"));
const ProductDetailsPage = React.lazy(() => import("./pages/product-details/ProductDetailsPage"));
const OrderConfirmation = React.lazy(() => import("./pages/OrderConfirmation"));
const SubscriptionConfirmation = React.lazy(() => import("./pages/SubscriptionConfirmation"));
const PickupConfirmation = React.lazy(() => import("./pages/PickupConfirmation"));
const CmsContentPreview = React.lazy(() => import("./components/cms/CmsContentPreview"));
const CmsContent = React.lazy(() => import("./components/cms/CmsContent"));
const ContactFormPage = React.lazy(() => import("./pages/ContactFormPage"));
const SwitchCompanyPunchoutPage = React.lazy(() => import("./pages/punchout/SwitchCompanyPunchoutPage"));
const AdventCalendar = React.lazy(() => import("./pages/AdventCalendarPage"));

const App = () => {
   const [location, redirectTo] = useLocation();

   useEffect(() => {
      async function fetchData() {
         await uiStore.ensureDataVersionAvailable();
         await featuresStore.fetchEnabledConfigCatFeatures();

         try {
            await contentStore.fillStore(location);
            void deliveryDatesStore.fetchHolidays();

            await productStore.fillStore();
            await categoryStore.fillStore();
         } catch (err) {
            const actualError: Error = ensureError(err);
            // If we end up here, likelihood is that we attempted to load an old data file from the CDN.
            // We will attempt to check versions on CDN and then refresh the window to fetch new data.
            await uiStore.handleMissingDataFileError(actualError);
         }

         try {
            const isImpersonating = authStore.useSessionFromQuery();
            if (isImpersonating) {
               redirectTo("/");
            }

            productStore.loadGuestAssortment();
            await authStore.verifySession(null);
         } catch (err) {
            if (!(err instanceof TokenExpiryError)) {
               const wrappedError: Error = ensureError(err);
               Bugsnag.notify(wrappedError, (e) => {
                  e.context = "Session Check during startup";
               });
               console.info("Not already logged in, reason: ", wrappedError);
            }
         }
      }

      void fetchData();

      window.addEventListener("keydown", uiStore.handleShortcutKeypress);
      return () => {
         window.removeEventListener("keydown", uiStore.handleShortcutKeypress);
      };
   }, []);

   const isDesktop = useMediaQuery(desktopBreakpoint);
   const isPunchoutCompanySelect = authStore.isPunchoutSession() && loginState.is("COMPANY_SELECTION");
   return (
      <>
         <Helmet>
            <title>{theme.siteName}</title>
         </Helmet>
         {isPunchoutCompanySelect && (
            <Suspense fallback={<Loading />}>
               <SwitchCompanyPunchoutPage />
            </Suspense>
         )}
         {!isPunchoutCompanySelect && (
            <>
               {process.env.ENV_NAME != "prod" && <PreprodInfo />}
               <TopBarWarnings />
               <header className="tw-relative tw-z-30 print:tw-hidden">
                  <ScrollToTop />
                  {isDesktop ? <DesktopHeader /> : <MobileHeader />}
               </header>
               <MainColumn as="main">
                  <div className="tw-min-h-[90vh] tw-w-full tw-pb-20 tw-pt-6">
                     <Suspense fallback={<Loading />}>
                        <Switch>
                           <Route path={/^(?<fullUrl>\/)$/} component={Home} />
                           <Route path="/home">
                              <Redirect to="/" replace />
                           </Route>
                           <Route path="/produkter/:cat2/:cat3">{(p) => <ProductListPage {...p} />}</Route>
                           <Route path="/produkter/:cat2">{(p) => <ProductListPage {...p} />}</Route>
                           <Route path="/produkter.html">{(p) => <ProductListPage {...p} />}</Route>
                           <Route path="/customer/*" component={CustomerRoutes} />
                           <Route path="/checkout/cart" component={Cart} />
                           <Route path="/checkout/confirmation/subscription" component={SubscriptionConfirmation} />
                           <Route path="/checkout/confirmation/pickup" component={PickupConfirmation} />
                           <Route path="/checkout/confirmation" component={OrderConfirmation} />
                           <Route path="/search/:type?" component={SearchResult} />
                           <Route path="/produkt/:slug">{(p) => <ProductDetailsPage {...p} />}</Route>
                           <Route path="/kontakt" component={ContactFormPage} />
                           <Route path="/julekalender" component={AdventCalendar} />
                           <Route path={/^\/_preview\/(?<fullUrl>.*)$/} component={CmsContentPreview} />
                           <Route path={/^\/(?<fullUrl>.*)$/} component={CmsContent} />
                        </Switch>
                     </Suspense>
                  </div>
                  <div id="megamenu-root" />
                  <div id="quicksearch-root" />

                  {uiStore.planes.map((p) => (
                     <FlyingProduct key={p.animationId} plane={p} />
                  ))}
                  {featuresStore.barcodeScannerEnabled && barcodeStore.modalVisible && (
                     <Suspense fallback={<Loading modal />}>
                        <BarcodeScanner />
                     </Suspense>
                  )}

                  <ChangeDeliveryDateModal />
                  <SwitchCompanyModal />
                  <ExpiredDeadlineModal />
               </MainColumn>
               <footer className="tw-bg-headerFooterBackground [&>*]:tw-text-headerFooterText">
                  <Footer />
               </footer>
            </>
         )}
         <ToastDisplay />
         <LoginModal />
         <LoginProgress />
         <ForcedLogoutModal />
         <SessionChecker onChange={(vis) => authStore.verifyStillLoggedIn(vis)} />
         <VurbisPunchoutScript />
      </>
   );
};

export default view(App);
