import { view } from "@risingstack/react-easy-state";
import { IconClock } from "@tine/designsystem-icons/sharp";
import { Button } from "@tine/designsystem-ui-react";
import isNil from "lodash-es/isNil";
import { useRef, useState } from "react";

import { translateDayLabel } from "../../common/utils";
import { formatDate, formatDateTime } from "../../common/utils/dateUtils";
import Popover from "../../components/common/popover/Popover";

import cartStore from "../../stores/cart/cartStore";
import deliveryDatesStore from "../../stores/deliveryDates/deliveryDatesStore";

import InfoLine from "../../components/InfoLine";

const MobileDeliveryInfo = () => {
   const ref = useRef<HTMLButtonElement>(null);
   const [show, setShow] = useState<boolean>(false);
   const currentDelivery = deliveryDatesStore.getCurrentDelivery();
   if (isNil(currentDelivery)) {
      return null;
   }
   const nextDelivery = deliveryDatesStore.getNextDeliveryDateForOrderType(cartStore.orderType)?.date;

   const dayLabel = translateDayLabel(cartStore.orderType);

   return (
      <>
         <Button icon={<IconClock />} ref={ref} variant="tertiary" onClick={() => setShow(!show)} />
         <Popover show={show} referenceElement={ref.current} onHide={() => setShow(false)}>
            <div className="tw-p-3">
               <InfoLine margin label={dayLabel} value={formatDate(currentDelivery.date, true, false, true)} />
               <InfoLine margin label="Bestillingsfrist" value={formatDateTime(currentDelivery.deadline, true, true)} />
               <InfoLine margin label="Første mulige levering" value={formatDate(nextDelivery, true, false, true)} />
            </div>
         </Popover>
      </>
   );
};

export default view(MobileDeliveryInfo);
