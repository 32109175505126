import { view } from "@risingstack/react-easy-state";
import { IconLightning } from "@tine/designsystem-icons/sharp";
import { Group } from "@tine/designsystem-ui-react";
import { cn } from "@tine/designsystem-utils";
import { Link } from "wouter";

import MainColumn from "../../components/common/MainColumn";

import authStore from "../../stores/auth/authStore";
import uiStore from "../../stores/uiStore";

import BarcodeScannerButton from "../../components/barcodes/BarcodeScannerButton";
import FavoriteListButton from "../../components/FavoriteListButton";
import LoginButton from "../../components/login/LoginButton";
import NewCustomerButton from "../../components/login/NewCustomerButton";
import SearchField from "../../components/search/SearchField";
import theme from "../../themes/theme";
import MiniCart from "../minicart/MiniCart";
import UserMenu from "./UserMenu";

const className = cn(["tw-bg-base-0", "tw-w-full", "tw-h-[75px]"]);
const DesktopSearchBar = () => (
   <div className={className}>
      <MainColumn>
         <Group className={className}>
            <div className="tw-mr-auto tw-flex tw-items-center">
               <Link to="/">
                  <img
                     role="button"
                     alt={"Logo"}
                     width={50}
                     height={Math.round(50 * theme.logoRatio)}
                     className="logo"
                     src={theme.desktopLogoUrl}
                     tabIndex={0}
                  />
               </Link>
               <div className="tw-ml-12 tw-mr-4 tw-min-w-fit lg:tw-min-w-[--search-input-min-width]">
                  <SearchField />
               </div>
               {uiStore.accessLevel === 2 && (
                  <div className="tw-font-bold tw-text-error-600">
                     <IconLightning /> Du har nå KS-tilgang. Last på nytt (F5) for å avslutte.
                  </div>
               )}
               {authStore.isLoggedIn() && (
                  <div>
                     <BarcodeScannerButton />
                  </div>
               )}
            </div>
            {authStore.isLoggedIn() ? (
               <>
                  <div>
                     <FavoriteListButton />
                  </div>
                  <div className="tw-pl-1 tw-pr-2">
                     <UserMenu />
                  </div>
               </>
            ) : (
               <>
                  <LoginButton />
                  <div className="tw-pl-2 tw-pr-4">
                     <NewCustomerButton />
                  </div>
               </>
            )}
            <div>
               <MiniCart />
            </div>
         </Group>
      </MainColumn>
   </div>
);

export default view(DesktopSearchBar);
