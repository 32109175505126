export const enum RECIPIENT_MODAL {
   NONE = "NONE",
   ADD = "ADD",
   EDIT = "EDIT",
   DELETE = "DELETE"
}

export type Recipient = {
   profileId: string;
   firstName: string;
   lastName: string;
   email: string;
   mobile: string;
   emailNotifications: string[];
   smsNotifications: string[];
   allNotifications: string[];
};

export type RecipientsResponse = {
   SubscriptionInformation: {
      Name: string | null;
      NameNo: string | null;
      Id: string;
   }[];
   ProfileList: {
      Id: string;
      FirstName: string | null;
      LastName: string | null;
      Email: string | null;
      Mobile: string | null;
      EmailNotifications: string[];
      SmsNotifications: string[];
   }[];
};

export type RecipientValidations = {
   firstName: ValidatorObject;
   lastName: ValidatorObject;
   email: ValidatorObject;
   mobile: ValidatorObject;
   notifications: ValidatorObject;
};

export type ValidatorObject = {
   isInvalid: boolean;
   message: string;
};

export type NotificationCategoryIdType =
   | "ORDER_CONFIRMATION"
   | "DELIVERY_CONFIRMATION"
   | "IMPORTANT_INFO_ABOUT_DELIVERY"
   | "ORDER_DEADLINE";

export type NotificationCategory = {
   id: NotificationCategoryIdType;
   name: string;
};
