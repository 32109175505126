import { view } from "@risingstack/react-easy-state";
import { startOfMonth } from "date-fns";
import { ReactNode } from "react";

import { sendDeliveryDateChange } from "../../common/tracking";
import { DayFlagLabel } from "../../common/types/calendarTypes";
import useMediaQuery from "../../common/useMediaQuery";
import { mediaQueries } from "../../common/utils";
import CalendarKeys from "../common/calendar/CalendarKeys";
import DatePicker from "../common/datepicker/DatePicker";

import availabilityStore from "../../stores/availabilityStore";
import cartStore from "../../stores/cart/cartStore";
import deliveryDatesStore from "../../stores/deliveryDates/deliveryDatesStore";

type DeliveryDateButtonProps = {
   render: (dates: Date[], isPickerOpen: boolean, setPickerOpen: (show: boolean) => void) => ReactNode;
   size?: "compact" | "regular";
};

const DeliveryDateButton = ({ render, size = "compact" }: DeliveryDateButtonProps) => {
   const isSm = useMediaQuery(mediaQueries.sm);
   const labels: DayFlagLabel[] = [
      { flag: "selected", label: "Leveringsdag" },
      { flag: "secondary", label: "Kan velges" },
      { flag: "cancelled", label: "Kansellert" }
   ];
   const handleChange = (dates: Date[]) => {
      const date = dates[0];
      if (date) {
         availabilityStore.clearAvailability();
         deliveryDatesStore.changeDeliveryDate(date);
         sendDeliveryDateChange();
      }
   };
   return (
      <DatePicker
         monthCount={isSm ? 2 : 1}
         min={startOfMonth(new Date())}
         render={render}
         size={size}
         dates={cartStore.deliveryDateForPicker()}
         onDatesChange={handleChange}
         getDayFlags={deliveryDatesStore.getDayFlags}
         closeOnChange={true}
      >
         <CalendarKeys flagLabels={labels} />
      </DatePicker>
   );
};

export default view(DeliveryDateButton);
