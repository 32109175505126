import { ImgHTMLAttributes } from "react";

import { AspectRatioType, ImagePlacementType } from "../../../common/types/mediaTypes";
import { createSourceSet, generateSizesFromMaxSize } from "../../../common/utils/imageUtils";

type RecipeImageProps = Omit<ImgHTMLAttributes<HTMLImageElement>, "sizes"> & {
   url: string;
   title: string;
   ratio: AspectRatioType;
   placement: ImagePlacementType;
   width: number;
   className?: string;
   sourceWidth?: number;
   sourceHeight?: number;
};

const urlForWidthAndHeight = (urlTemplate: string, width: number, height: number) => {
   return urlTemplate.replace("%height%", height.toFixed(0)).replace("%width%", width.toFixed(0));
};

const calculateHeight = (width: number, ratio: AspectRatioType) => {
   return ratio === "32x9"
      ? Math.round(((width ?? 0) * 9) / 32)
      : ratio === "16x9"
        ? Math.round(((width ?? 0) * 9) / 16)
        : Math.round(((width ?? 0) * 9) / 21);
};

const RecipeImage = ({
   url,
   width,
   ratio,
   placement,
   title,
   sourceHeight,
   sourceWidth,
   className = "",
   ...rest
}: RecipeImageProps) => {
   const height = calculateHeight(width, ratio);
   let maxWidth = width,
      maxHeight = height;
   if (sourceWidth && sourceHeight) {
      if (sourceWidth < maxWidth || sourceHeight < maxHeight) {
         maxWidth = sourceWidth;
         maxHeight = calculateHeight(maxWidth, ratio);
         if (maxHeight > sourceHeight) {
            maxWidth = (sourceWidth * sourceHeight) / maxHeight;
            maxHeight = sourceHeight;
         }
      }
   }
   const sizes = generateSizesFromMaxSize(maxWidth, maxHeight);
   const sourceSetItems = createSourceSet(sizes, (width, height) => urlForWidthAndHeight(url, width, height));
   const itemsForSourceTags = sourceSetItems.filter((sourceSetItem) => sourceSetItem.media.length > 0);
   const itemForImgTag = sourceSetItems.find((sourceSetItem) => sourceSetItem.media.length === 0);

   const preferredLoadingStrategy = placement === "aboveFold" ? "eager" : "lazy";

   return (
      <div>
         <picture>
            {itemsForSourceTags.map((sourceSetItem) => (
               <source
                  key={sourceSetItem.media + sourceSetItem.width}
                  srcSet={sourceSetItem.url}
                  media={sourceSetItem.media}
                  width={sourceSetItem.width}
                  height={sourceSetItem.height}
               />
            ))}
            {itemForImgTag && (
               <img
                  {...rest}
                  className={`tw-h-full tw-w-full tw-rounded ${className}`}
                  alt={title}
                  src={itemForImgTag.url}
                  width={itemForImgTag.width}
                  height={itemForImgTag.height}
                  loading={preferredLoadingStrategy}
               />
            )}
         </picture>
      </div>
   );
};

export default RecipeImage;
