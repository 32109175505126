import { cn } from "@tine/designsystem-utils";
import { KeyboardEventHandler, MouseEventHandler, ReactNode, useRef } from "react";
import { Link, useRoute } from "wouter";

type NavLinkProps = {
   to?: string;
   onClick?: MouseEventHandler;
   children: ReactNode;
};

const likeALinkCN = cn(["hover:tw-cursor-pointer hover:tw-underline"]);

const NavLink = ({ to, children, onClick }: NavLinkProps) => {
   const hiddenAnchorRef = useRef<HTMLAnchorElement>(null);
   if (typeof to === "undefined") {
      const handleKeydown: KeyboardEventHandler<HTMLDivElement> = (evt) => {
         const { key } = evt;
         if (key === "Enter" && hiddenAnchorRef.current) {
            hiddenAnchorRef.current.click();
         }
      };
      return (
         <>
            <div className={likeALinkCN} tabIndex={0} role="link" onClick={onClick} onKeyDown={handleKeydown}>
               {children}
            </div>
            <a className="!tw-hidden" ref={hiddenAnchorRef} href="#" onClick={onClick}></a>
         </>
      );
   }

   const [isActivePath] = useRoute(new RegExp(`(${to}|${to}/*)`));

   const ariaCurrent = isActivePath ? "page" : undefined;
   const className = isActivePath ? "tw-text-underline" : undefined;

   return (
      <Link to={to} onClick={onClick} asChild>
         <a aria-current={ariaCurrent} className={className}>
            {children}
         </a>
      </Link>
   );
};

export default NavLink;
