import { view } from "@risingstack/react-easy-state";

import cartStore from "../../stores/cart/cartStore";

import MiniCartOrderLines from "./MiniCartOrderLines";

const EmptyCart = view(() => (
   <div className="tw-grow tw-flex tw-flex-col tw-justify-center tw-items-center">
      <h4>Du har ingen varer i handlekurven</h4>
   </div>
));

const MiniCartContent = () => {
   const items = cartStore.items;
   const empty = items.length === 0;

   return !empty ? <MiniCartOrderLines items={items} /> : <EmptyCart />;
};

export default view(MiniCartContent);
