import { view } from "@risingstack/react-easy-state";
import { Group } from "@tine/designsystem-ui-react";
import { cn } from "@tine/designsystem-utils";

import MainColumn from "../../components/common/MainColumn";

import authStore from "../../stores/auth/authStore";

import BarcodeScannerButton from "../../components/barcodes/BarcodeScannerButton";
import FavoriteListButton from "../../components/FavoriteListButton";
import LoginButton from "../../components/login/LoginButton";
import NewCustomerButton from "../../components/login/NewCustomerButton";
import MiniCart from "../minicart/MiniCart";
import MobileCategories from "./MobileCategories";
import MobileDeliveryInfo from "./MobileDeliveryInfo";

const className = cn(["tw-bg-[--color-base-0]", "tw-w-full"]);

const groupClassName = cn(["tw-my-2", "tw-w-full"]);

const BurgerMenuAndCart = () => (
   <div className={className}>
      <MainColumn>
         <Group align="center" justify="between" className={groupClassName}>
            <MobileCategories />

            <div className="tw-flex tw-items-center tw-justify-end tw-p-0">
               {authStore.isLoggedIn() ? (
                  <>
                     <BarcodeScannerButton className="tw-mx-2" />
                     <FavoriteListButton />
                     <MobileDeliveryInfo />
                  </>
               ) : (
                  <>
                     <LoginButton />
                     <NewCustomerButton />
                  </>
               )}
               <div className="tw-pl-4">
                  <MiniCart />
               </div>
            </div>
         </Group>
      </MainColumn>
   </div>
);

export default view(BurgerMenuAndCart);
