import { Document } from "@contentful/rich-text-types";
import { store } from "@risingstack/react-easy-state";
import { Entry } from "contentful";
import isNil from "lodash-es/isNil";

import { ensureError } from "../../common/errors";
import {
   ContentEntryType,
   ContentfulArticle,
   ContentfulProductCategory,
   ContentfulSection,
   FocalPointData,
   ImageWithFocusData,
   MenuItem,
   SectionRecipeOrArticleOrCalendarContent,
   UrlData,
   WarningFields
} from "../../common/types/cmsTypes";
import {
   TypeFooterSkeleton,
   TypeImageWithFocusSkeleton,
   TypeRecipeSkeleton,
   TypeV2_articleSkeleton
} from "../../common/types/contentful";
import {
   AsyncData,
   initializeWithDefaultData,
   isDataAvailable,
   isDefaultData,
   setAsDataAvailable,
   setAsErrorOccured,
   setAsLoading,
   setAsWaitingForData
} from "../../common/utils/asyncDataUtils";

import {
   contentfulClient,
   createMenuItem,
   generateFullUrl,
   getAdventCalendarSlotSlugsAndParents,
   getArticlesInSection,
   getArticleSlugsAndParents,
   getFooterContent,
   getFullContent,
   getProductCategoryContent,
   getRecipesInSection,
   getRecipeSlugsAndParents,
   getSectionsSlugsAndParents,
   getUnprocessedUrlDataFromContent,
   getWarnings,
   PREVIEW_BASE_PATH,
   sortSectionContentEntries
} from "./contentUtils";
import segmentStore, { SegmentedEntrySkeleton } from "./segmentStore";

const CONTRACTUAL_RECOMMENDATIONS_TAG = "contractualRecommendationsArticle";

// Used to cache requests for imageWithFocus resources
const cachedImageWithFocusPromise: Record<string, Promise<ImageWithFocusData | undefined>> = {};

type ContentStore = {
   urlData: AsyncData<UrlData[]>;
   fetchedContent: {
      [id: string]: { type: ContentEntryType; entry: AsyncData<any> };
   };
   sectionContent: {
      [id: string]: AsyncData<Entry<TypeV2_articleSkeleton | TypeRecipeSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>[]>;
   };
   sections: AsyncData<ContentfulSection[]>;
   productCategories: AsyncData<ContentfulProductCategory[]>;
   mainMenuItems: MenuItem[];
   contractualArticles: ContentfulArticle[];
   isPreviewMode: boolean;
   footerContent: AsyncData<Entry<TypeFooterSkeleton, undefined, string> | undefined>;
   fillStore: (location: string) => Promise<void>;
   fetchSectionContentForId: (sectionId: string) => Promise<void>;
   getImageWithFocusUsingCache: (entryId: string) => Promise<ImageWithFocusData | undefined>;
   getImageWithFocus: (entryId: string) => Promise<ImageWithFocusData | undefined>;
   fetchContentIfNotAlreadyInStore: (id: string, type: ContentEntryType) => void;
   getRichTextForProductCategory: (path: string) => Document | null;
   getCurrentWarnings: () => WarningFields[];
};

const contentStore: ContentStore = store({
   urlData: initializeWithDefaultData([]),
   sections: initializeWithDefaultData([]),
   mainMenuItems: [],
   contractualArticles: [],
   sectionContent: {},
   fetchedContent: {},
   isPreviewMode: false,
   footerContent: initializeWithDefaultData(undefined),
   productCategories: initializeWithDefaultData([]),
   getCurrentWarnings: () => {
      return Object.entries(contentStore.fetchedContent)
         .filter((kv) => {
            const isWarning = kv[1].type === "warning";
            if (isWarning) {
               const visibleForCurrentSegment = segmentStore.isArticleVisible(kv[0]);
               return visibleForCurrentSegment;
            }
            return false;
         })
         .map((kv) => kv[1].entry.data);
   },

   /**
    * Why a separate cache wrapper method?
    * This method stores the promise generated the first time a call is made to fetch a specific
    * imageWithFocus. If multiple components call this method at the same time with the same entryId,
    * they will all get the same promise and it will be resolved with one request. If the same
    * entryId is requested later within the same session, the already-resolved promise is returned and
    * response reused without calling the API again.
    */
   getImageWithFocusUsingCache: (entryId): Promise<ImageWithFocusData | undefined> => {
      if (cachedImageWithFocusPromise.hasOwnProperty(entryId)) {
         return cachedImageWithFocusPromise[entryId];
      }

      const promise = contentStore.getImageWithFocus(entryId);
      cachedImageWithFocusPromise[entryId] = promise;

      return promise;
   },

   getImageWithFocus: async (entryId): Promise<ImageWithFocusData | undefined> => {
      const entry = await contentfulClient(
         contentStore.isPreviewMode
      ).withoutUnresolvableLinks.getEntry<TypeImageWithFocusSkeleton>(entryId);

      if (isNil(entry) || !entry.fields.image) {
         throw new Error("Image with focus either unavailable or does not contain image info");
      }

      const focalPoint = entry.fields.focalPoint as FocalPointData;

      return {
         entryId,
         altText: entry.fields.altText,
         focalPoint: focalPoint.focalPoint,
         originalAssetUrl: entry.fields.image.fields.file?.url
      };
   },

   fillStore: async (location) => {
      contentStore.isPreviewMode = location.split("/")[1] === PREVIEW_BASE_PATH;
      setAsWaitingForData(contentStore.urlData);
      setAsWaitingForData(contentStore.sections);
      setAsWaitingForData(contentStore.footerContent);
      setAsWaitingForData(contentStore.productCategories);

      try {
         const [
            sectionContent,
            articleContent,
            recipeContent,
            productCategoryContent,
            adventCalendarSlotContent,
            warningContent
         ] = await Promise.all([
            getSectionsSlugsAndParents(contentStore.isPreviewMode),
            getArticleSlugsAndParents(contentStore.isPreviewMode),
            getRecipeSlugsAndParents(contentStore.isPreviewMode),
            getProductCategoryContent(contentStore.isPreviewMode),
            getAdventCalendarSlotSlugsAndParents(contentStore.isPreviewMode),
            getWarnings(contentStore.isPreviewMode)
         ]);
         const segmentedEntries: Entry<SegmentedEntrySkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>[] = [
            ...articleContent,
            ...productCategoryContent,
            ...warningContent
         ];
         segmentStore.setArticleSegments(segmentedEntries);

         const sectionsRecipesAndArticles: SectionRecipeOrArticleOrCalendarContent[] = [
            ...sectionContent,
            ...articleContent,
            ...recipeContent,
            ...adventCalendarSlotContent
         ];

         const allContent = sectionsRecipesAndArticles.map(getUnprocessedUrlDataFromContent);
         const allUrlData = allContent.map((page) => generateFullUrl(page, allContent));

         // Find content marked as main menu content
         contentStore.mainMenuItems = sectionsRecipesAndArticles.flatMap((content) => createMenuItem(content, allUrlData));

         contentStore.contractualArticles = articleContent.filter((article) =>
            article.metadata.tags.some((tag) => tag.sys.id === CONTRACTUAL_RECOMMENDATIONS_TAG)
         );

         // Initialize all possible keys for content fetching in the future
         allUrlData.forEach((data) => {
            contentStore.fetchedContent[data.id] = { type: data.type, entry: initializeWithDefaultData({}) };
         });

         warningContent.forEach((warning) => {
            contentStore.fetchedContent[warning.sys.id] = { type: "warning", entry: initializeWithDefaultData(warning.fields) };
         });

         const footerData = await getFooterContent(contentStore.isPreviewMode);
         setAsDataAvailable(contentStore.footerContent, footerData[0]);
         setAsDataAvailable(contentStore.productCategories, productCategoryContent);
         setAsDataAvailable(contentStore.sections, sectionContent);
         setAsDataAvailable(contentStore.urlData, allUrlData);
      } catch (err) {
         console.warn("An error while building url data", err);
         const errorMsg = ensureError(err).message;
         setAsErrorOccured(contentStore.sections, errorMsg);
         setAsErrorOccured(contentStore.urlData, errorMsg);
      }
   },

   fetchSectionContentForId: async (sectionId) => {
      console.log("Fetching article list for section id " + sectionId);
      const sectionContent = contentStore.sectionContent[sectionId];
      setAsWaitingForData(sectionContent);

      try {
         const articles = await getArticlesInSection(sectionId, contentStore.isPreviewMode);
         const recipes = await getRecipesInSection(sectionId, contentStore.isPreviewMode);
         const content = [...sortSectionContentEntries(articles), ...sortSectionContentEntries(recipes)];

         setAsDataAvailable(sectionContent, content);
      } catch (err) {
         setAsErrorOccured(sectionContent, ensureError(err).message);
      }
   },

   fetchContentIfNotAlreadyInStore: async (id: string, type: ContentEntryType) => {
      if (!isDefaultData(contentStore.fetchedContent[id].entry)) {
         return;
      }

      console.log(`Starting full content fetch for id ${id} of type ${type}`);
      setAsLoading(contentStore.fetchedContent[id].entry);

      try {
         const result = await getFullContent(id, contentStore.isPreviewMode);
         console.log(`Full content results for ${id} (${type}):`, result);
         setAsDataAvailable(contentStore.fetchedContent[id].entry, result);
      } catch (err) {
         console.warn("Error during fetching full content", err);
         setAsErrorOccured(contentStore.fetchedContent[id].entry, ensureError(err).message);
      }
   },
   getRichTextForProductCategory: (path: string): Document | null => {
      if (isDataAvailable(contentStore.productCategories)) {
         const entries: ContentfulProductCategory[] = contentStore.productCategories.data;
         const filteredEntries = entries.filter((entry) => {
            return entry.fields.categoryPath === path && segmentStore.isArticleVisible(entry.sys.id);
         });
         if (filteredEntries.length) {
            return filteredEntries[0].fields.body;
         }
      }
      return null;
   }
});

export default contentStore;
