import { autoEffect, store, view } from "@risingstack/react-easy-state";
import { Alert, Input } from "@tine/designsystem-ui-react";
import isNil from "lodash-es/isNil";
import { KeyboardEventHandler } from "react";

import { sendLoginStep } from "../../common/tracking";
import { formatOtp, formatPhoneNumber } from "../../common/utils";
import { getPhoneNumberFromStorage, setPhoneNumberInStorage } from "../../common/utils/storageUtils";

import authStore from "../../stores/auth/authStore";
import uiStore, { LOGIN_MODE } from "../../stores/uiStore";

import Button from "../Button";

type FormType = {
   mobileNumber: string;
   code: string;
   error: "code" | "mobile" | null;
   loading: boolean;
};

const LoginSms = () => {
   const form: FormType = store({
      mobileNumber: getPhoneNumberFromStorage(),
      code: "",
      error: null,
      loading: false
   });

   autoEffect(() => setPhoneNumberInStorage(form.mobileNumber));

   const requestCode = () => {
      if (form.mobileNumber.length === 0) {
         form.error = "mobile";
         return;
      }

      if (form.loading) {
         return;
      }

      form.loading = true;
      authStore.requestSmsLoginOtp(form.mobileNumber).finally(() => {
         form.loading = false;
      });
      sendLoginStep();
   };

   const verifyCode = () => {
      if (form.code.length === 0) {
         form.error = "code";
         return;
      }

      authStore
         .verifySmsLoginOtp(form.code)
         .then(() => {
            form.code = "";
            form.mobileNumber = "";
         })
         .catch((err) => {
            form.error = err;
         });
   };

   const abortSMSLogin = () => {
      uiStore.switchLoginMode(LOGIN_MODE.USERNAME);
      authStore.codeChallengeId = null;
   };

   const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = (event) => {
      form.error = null;
      if (event.key === "Enter") {
         if (isNil(authStore.codeChallengeId)) {
            requestCode();
         } else {
            verifyCode();
         }
      }
   };

   const isCodeSent = !isNil(authStore.codeChallengeId);

   return (
      <div className="tw-flex tw-flex-col">
         <div className="tw-pb-2">
            <h2>
               <b>Logg inn med mobilnummer</b>
            </h2>
         </div>
         <div className="tw-flex tw-flex-col tw-gap-4 tw-pb-5">
            {!isCodeSent ? (
               <p>Fyll inn mobilnummer og motta engangskode på SMS.</p>
            ) : (
               <Alert variant="success" showCloseButton={false}>
                  En SMS med engangskode er sendt til mobilnummeret som er registrert på din konto.
               </Alert>
            )}

            <Input
               label="Mobilnummer"
               placeholder="8 siffer"
               value={form.mobileNumber}
               onChange={(e) => {
                  form.mobileNumber = formatPhoneNumber(e.target.value);
               }}
               onKeyDown={handleKeyPress}
               autoFocus
               disabled={isCodeSent}
               type="tel"
               showErrorMessage={form.error === "mobile"}
            />

            <Input
               label="Engangskode fra SMS"
               value={form.code}
               placeholder="6 siffer"
               onChange={(e) => {
                  // Last parameter used to be e.currentTarget.prev - TODO: Ask Fredrik how this worked
                  form.code = formatOtp(e.target.value, e.target.value);
               }}
               type="otp"
               disabled={!isCodeSent}
               autoFocus={isCodeSent}
               showErrorMessage={form.error === "code"}
               errorMessage="Koden som er angitt stemmer ikke. Vennligst prøv på nytt."
            />
         </div>
         <Button
            variant="primary"
            size="regular"
            onClick={isNil(authStore.codeChallengeId) ? requestCode : verifyCode}
            loading={form.loading}
            disabled={form.loading}
         >
            {isNil(authStore.codeChallengeId) ? "Send engangskode" : "Logg inn med kode"}
         </Button>

         {isCodeSent ? (
            <div className="tw-pt-6">
               <Alert variant="information" showCloseButton={false}>
                  Har du ikke mottatt en SMS fra oss? Logg inn med <strong>e-post/kundenummer</strong> og oppdater mobilnummer på
                  din konto.
               </Alert>
            </div>
         ) : null}

         <div className="tw-flex tw-items-center tw-gap-2 tw-py-2">
            <hr className="login-divider" />
            <p className="tw-m-0 tw-text-center">Eller</p>
            <hr className="login-divider" />
         </div>

         <Button size="regular" variant="secondary" onClick={abortSMSLogin}>
            Logg inn med e-post/kundenummer
         </Button>
      </div>
   );
};

export default view(LoginSms);
