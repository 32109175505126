import { view } from "@risingstack/react-easy-state";

import { Product } from "../../common/types/productTypes";
import { AlgoliaProductHit, ProductSearchResults, SEARCH_TYPE, SearchComponentProps } from "../../common/types/searchTypes";
import useSupportedLocale from "../../common/utils/useSupportedLocale";

import featuresStore from "../../stores/features/featuresStore";
import { prioritizeAgreementAssortment } from "../../stores/product/productUtils";
import searchStore from "../../stores/searchStore";

import { InternalLink } from "../Links";
import ProductHit from "./ProductHit";

const hide = () => searchStore.clear(SEARCH_TYPE.QuickSearch, true);

const HitsForProduct = ({ searchDomain, maxHits = Number.MAX_VALUE }: SearchComponentProps) => {
   const locale = useSupportedLocale();

   const productResults: ProductSearchResults | undefined = searchDomain
      ? (searchStore[SEARCH_TYPE.QuickSearch].results.find(
           (result) => result.name === searchDomain.name && searchDomain.type === "product"
        ) as ProductSearchResults | undefined)
      : undefined;

   if (!productResults) {
      return null;
   }

   const sortedProducts = prioritizeAgreementAssortment(productResults.products, featuresStore.prioritizeAgreementAssortment);

   const queryID = productResults.queryID;
   const products = sortedProducts.filter((p) => p).slice(0, maxHits);
   const productsCount = products?.length;
   const hasProducts = !!products?.length;

   return (
      <>
         {hasProducts && (
            <div className="tw-flex tw-w-full tw-flex-col tw-gap-2">
               <div>
                  <h3 className="tw-pb-2">{searchDomain.displayName(locale)}</h3>
               </div>
               <div className="tw-grid tw-gap-y-2 lg:tw-grid-cols-3 lg:tw-gap-x-8">
                  {products.map((product: Product, i: number) => {
                     const hit = productResults.hits.find((h) => h.objectID === product.sku) as AlgoliaProductHit;
                     return (
                        <ProductHit
                           key={product.sku}
                           hit={hit}
                           product={product}
                           position={i + 1}
                           algoliaIndex={searchDomain?.indexName || ""}
                           queryID={queryID}
                           numberOfHits={productsCount}
                        />
                     );
                  })}
               </div>
               <div>
                  <InternalLink
                     className="tw-my-2 tw-inline-block tw-p-2"
                     onClick={() => hide()}
                     href={`/search/${searchDomain.name}?q=${productResults.query}`}
                  >{`${searchDomain.linkText(locale)} med '${productResults.query}'`}</InternalLink>
               </div>
            </div>
         )}
      </>
   );
};

export default view(HitsForProduct);
