import { view } from "@risingstack/react-easy-state";
import { IconCheckCircle, IconSpinner } from "@tine/designsystem-icons/sharp";
import isNil from "lodash-es/isNil";

import { formatDate } from "../common/utils/dateUtils";

import loginState from "../stores/auth/loginState";
import cartStore from "../stores/cart/cartStore";
import deliveryDatesStore from "../stores/deliveryDates/deliveryDatesStore";

import Modal from "../components/ModalWithTracking";

type IconIndicatorProps = {
   target: number;
   progress: number;
};

const IconIndicator = view(({ target, progress }: IconIndicatorProps) => {
   if (progress === target) {
      return <IconSpinner className="tw-size-5 tw-animate-spin" />;
   } else if (progress > target) {
      return <IconCheckCircle className="tw-size-5 tw-text-ink-success" />;
   } else {
      return <div className="tw-size-5">&nbsp;</div>;
   }
});

type ProgressLineProps = {
   step: number;
   label: string;
   progress: number;
};

const ProgressLine = view(({ step, label, progress }: ProgressLineProps) => {
   return (
      <div className="tw-flex tw-items-center tw-gap-1">
         <IconIndicator target={step} progress={progress} />
         <div>{label}</div>
      </div>
   );
});

const LoginProgress = () => {
   let assortmentText = "Henter deres produkter og priser";
   if (!isNil(deliveryDatesStore.getCurrentDelivery()) && !isNil(cartStore.orderType)) {
      assortmentText += " for ";
      assortmentText += formatDate(deliveryDatesStore.getCurrentDelivery()?.date, true);
   }

   let progress = 0;
   switch (loginState.currentState) {
      case "VERIFY_SESSION":
         progress = 1;
         break;
      case "LOGGING_IN":
         progress = 2;
         break;
      case "COMPANY_SELECTED":
         progress = 2;
         break;
      case "FEATURES_LOADING":
         progress = 3;
         break;
      case "DELIVERY_DATES_LOADING":
         progress = 4;
         break;
      case "ASSORTMENT_LOADING":
         progress = 4;
         break;
      case "NOTIFICATIONS_LOADING":
         progress = 5;
         break;
   }

   return (
      <Modal
         name="Innloggingsfremdrift"
         isOpen={progress != 0}
         title="Gjør klar butikken..."
         className="tw-space-y-2"
         size="lg"
         showCloseButton={false}
      >
         <ProgressLine step={1} progress={progress} label="Verifiserer innlogging" />
         <ProgressLine step={2} progress={progress} label="Henter info om kundeforholdet" />
         <ProgressLine step={3} progress={progress} label="Skrur på korrekte ordretyper" />
         <ProgressLine step={4} progress={progress} label={assortmentText} />
         <ProgressLine step={5} progress={progress} label="Henter eventuelle varslinger" />
      </Modal>
   );
};

export default view(LoginProgress);
