import { Link, LinkProps } from "@tine/designsystem-ui-react";

type PhoneLinkProps = {
   telephoneNumber: string;
} & Omit<LinkProps, "children">;

const cleanUpTelephoneNumber = (telNr: string) => telNr.replace(/[^0-9+]/g, "");

const PhoneLink = ({ telephoneNumber, ...props }: PhoneLinkProps) => {
   const phone = cleanUpTelephoneNumber(telephoneNumber);
   if (!phone.length) {
      return null;
   }
   const href = `tel:${phone}`;

   return (
      <Link iconPosition="left" {...props} href={href}>
         {telephoneNumber}
      </Link>
   );
};

export default PhoneLink;
