import { view } from "@risingstack/react-easy-state";
import cn from "classnames";
import isString from "lodash-es/isString";
import { MouseEventHandler, ReactElement } from "react";

type Justification = "start" | "end" | "center" | "between" | "around";
type Alignment = "auto" | "start" | "end" | "center" | "stretch" | "baseline";

type MiniCartInfoLineProps = {
   label?: string | null;
   labelClassName?: string;
   value: string | number | null | undefined | ReactElement;
   valueClassName?: string;
   className?: string;
   align?: Justification;
   valign?: Alignment;
   margin?: boolean;
   rowOnMobile?: boolean;
   onClick?: MouseEventHandler<HTMLDivElement>;
};

const justifyClassName: { [alignment in Justification]: string } = {
   start: "tw-justify-start",
   around: "tw-justify-around",
   between: "tw-justify-between",
   end: "tw-justify-end",
   center: "tw-justify-center"
};

const lgAlignItemsClassName: { [alignment in Alignment]: string } = {
   start: "@[575px]:tw-items-start",
   auto: "@[575px]:tw-items-auto",
   stretch: "@[575px]:tw-items-stretch",
   end: "@[575px]:tw-items-end",
   center: "@[575px]:tw-items-center",
   baseline: "@[575px]:tw-items-baseline"
};

/**
 * The InfoLine element displays a row of data with a label and a value.
 * On desktop the label is shown on the same line as the value.
 * On mobile the label is shown above the line with the value because the label and value often take too much horisontal space.
 */
const MiniCartInfoLine = ({
   label,
   labelClassName = "",
   value,
   valueClassName = "",
   className = "",
   align = "between",
   valign = "center",
   margin = false,
   rowOnMobile = false,
   onClick = () => {}
}: MiniCartInfoLineProps): ReactElement => {
   const classNames = cn(`tw-flex ${justifyClassName[align]}`, className, {
      [`tw-flex-col tw-flex-wrap tw-items-start @[575px]:tw-flex-row ${lgAlignItemsClassName[valign]}`]: !rowOnMobile,
      "tw-mb-2": margin
   });

   return (
      <div className={classNames} onClick={onClick}>
         <div className={`@[575px]:tw-pr-1 ${labelClassName}`}>{isString(label) ? label + ":" : ""}</div>
         <div className={`@[575px]:tw-pl-1 @[575px]:tw-text-right ${valueClassName} ${isString(value) ? "tw-font-bold" : ""}`}>
            {value ?? ""}
         </div>
      </div>
   );
};

export default view(MiniCartInfoLine);
