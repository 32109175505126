import { OrderLineAppliedPromotion } from "./orderUtilsAndApi";

type OrderLineAppliedPromotionRecord = {
   [sku: string]: OrderLineAppliedPromotion[];
};

// Converts the order comment string from M3 into discount information
export const convertOrderCommentStringToObject = (orderCommentString: string): OrderLineAppliedPromotionRecord => {
   if (!orderCommentString || !(orderCommentString.startsWith("dspde:") && orderCommentString.includes("§"))) {
      return {};
   }

   const orderCommentMatrix = orderCommentString
      ?.slice(6)
      .split("|")
      .map((item) => item.split("§"));

   return orderCommentMatrix?.reduce((acc: OrderLineAppliedPromotionRecord, item) => {
      if (!!acc[item[0]]) {
         acc[item[0]].push({ appliedDiscount: Number(item[1].replace(",", ".")), discountName: item[2], discountId: item[3] });
         return acc;
      }
      acc[item[0]] = [{ appliedDiscount: Number(item[1].replace(",", ".")), discountName: item[2], discountId: item[3] }];
      return acc;
   }, {});
};
