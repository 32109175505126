// @ts-ignore
import logoUrl from "url:/src/img/themes/diplomis/logo.png?width=67";
// @ts-expect-error
import notfoundJpegLarge from "url:/src/img/themes/diplomis/not_found.jpg";
// @ts-expect-error
import notfoundWebpLarge from "url:/src/img/themes/diplomis/not_found.jpg?as=webp";
// @ts-expect-error
import notfoundJpegSmall from "url:/src/img/themes/diplomis/not_found.jpg?width=768";
// @ts-expect-error
import notfoundWebpSmall from "url:/src/img/themes/diplomis/not_found.jpg?width=768&as=webp";

import { CustomerFeature, FEATURE_NAME } from "../common/types/featureTypes";
import { Invoice } from "../common/types/invoiceTypes";
import { SupportedLocaleType } from "../common/types/languageTypes";
import { AccessoryType, CustomerType, Product } from "../common/types/productTypes";
import { SiteConfig } from "../common/types/themeTypes";

const CONTENTFUL_ENV = process.env.CONTENTFUL_ENV ?? "";
const ALGOLIA_APPID = "KJ28FN3CEH";
const ALGOLIA_SEARCHKEY = "5e181b299b6ea57f88b628c867a2f1b9";
const algoliaEnvironment = process.env.ENV_NAME === "prod" ? "prod" : "preprod";

type ProductEnhancement = {
   customerType?: CustomerType;
   accessoryType?: AccessoryType;
};
/**
 * Parses the response bodies into suitable data structure for use in stores. Diplomis does not up pickup alternatives
 */

const diplomisSiteConfig: SiteConfig = {
   storeId: "diplomis",
   storeCompanyName: "Diplom-Is",
   siteName: "Diplom-Is Handel",
   orderSupportEmail: "varebestilling@diplom-is.no",
   favIcon: "lolipop-icon.svg",
   m3CompanyNumber: "500",
   m3DivisionNumber: "100",
   desktopLogoUrl: logoUrl,
   mobileLogoUrl: logoUrl,
   logoRatio: 0.71,
   productDropdownEnabled: false,
   showComparisonPrice: false,
   faqSlug: "",
   filterAttributes: (variant) =>
      variant === "tilbehor" ? { customerType: "Type", accessoryType: "Kategori" } : { brand: "Merkevare" },
   filterRefreshOnChange: true,
   tipApiPrefix: "di-",
   papirflyApsCustomerId: "12843",
   enhanceProduct: (product: Product): Product => {
      const parentCategoryCode = product.parentCategory?.externalReference;
      const categoryCode = product.category?.externalReference;
      let productEnhancement: ProductEnhancement = {};
      if (parentCategoryCode === "036") {
         productEnhancement.customerType = "Dagligvare";
      } else {
         productEnhancement.customerType = "Isbar";
      }
      if (categoryCode === "0351") {
         productEnhancement.accessoryType = "Saus";
      } else if (categoryCode === "0352") {
         productEnhancement.accessoryType = "Strøssel";
      } else if (categoryCode === "0354") {
         productEnhancement.accessoryType = "Kjeks";
      } else if (["F61", "099"].includes(parentCategoryCode || "")) {
         productEnhancement.accessoryType = "Softisdeler";
      } else if (["035", "036"].includes(parentCategoryCode || "")) {
         productEnhancement.accessoryType = "Annet tilbehør";
      }

      return { ...product, ...productEnhancement };
   },
   fetchCustomerFeatures: () => {
      return Promise.resolve([
         { name: FEATURE_NAME.subscriptionOrderAvailable, description: "Makes subscriptions available" },
         { name: FEATURE_NAME.invoicePaymentAvailable, description: "Makes invoice overview available" },
         { name: FEATURE_NAME.createOrderAvailable, description: "Allows customer to place orders" }
      ] satisfies CustomerFeature[]);
   },
   invoiceTooltipText: (invoice: Invoice) => ``,
   deliveryFee: {
      enabled: true
   },
   productAvailability: {
      enabled: true
   },
   newCustomerForm: "https://kundeskjema.diplom-is.no/",
   deliveryNotes: {
      visible: false
   },
   contentImages: {
      cloudinaryMapping: "diplomis_images"
   },
   searchConfig: {
      algoliaConfig: {
         appId: ALGOLIA_APPID,
         searchApiKey: ALGOLIA_SEARCHKEY
      },
      searchDomains: [
         {
            type: "product",
            name: "produkter",
            displayName: (locale: SupportedLocaleType) => (locale === "en-US" ? "Products" : "Produkter"),
            homePath: (_locale) => "/produkter.html",
            indexName: `products_${algoliaEnvironment}`,
            linkText: (_locale) => "Se alle produkter"
         },
         {
            type: "v2_article",
            name: "artikler",
            displayName: (locale: SupportedLocaleType) => (locale === "en-US" ? "Articles" : "Artikler"),
            homePath: (_locale) => "/artikler",
            indexName: `contentful_${CONTENTFUL_ENV}`,
            linkText: (_locale) => "Se alle artikler"
         }
      ]
   },
   contactForm: {
      apiPath: "/api/di-contactform",
      reasons: [
         { value: "claims", label: "Reklamasjon", label_en: "Claims" },
         { value: "invoice", label: "Fakturaspørsmål / endring av fakturaadresse", label_en: "Invoice address changes" },
         { value: "delivery", label: "Ønske om levering før oppsatt leveringsdag", label_en: "Questions about ordering" },
         { value: "product_question", label: "Spørsmål om våre produkter eller sortiment", label_en: "Question about products" },
         { value: "other", label: "Annet", label_en: "Other" }
      ]
   },
   whileYouWaitMessage: "Ta frem en Royal Trippel fra fryseren, len deg tilbake og nyt et par sekunders velfortjent pause!",
   notFound: {
      img: {
         jpeg: {
            small: notfoundJpegSmall,
            large: notfoundJpegLarge
         },
         webp: {
            small: notfoundWebpSmall,
            large: notfoundWebpLarge
         },
         alt: "Kiosken er stengt, hvor finner jeg isen?  Ikke her, du får bare 404."
      }
   },
   notifications: {
      categories: [
         { id: "ORDER_CONFIRMATION", name: "Ordrebekreftelse" },
         { id: "DELIVERY_CONFIRMATION", name: "Leveringsbekreftelse" },
         { id: "ORDER_DEADLINE", name: "Påminnelse om ordrefrist" }
      ],
      enableDeliveryMessages: false
   },
   cart: {
      customerReferenceLabel: "Melding til sjåfør"
   }
};

export default diplomisSiteConfig;
