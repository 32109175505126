import { view } from "@risingstack/react-easy-state";
import { ButtonProps, Button as TineButton } from "@tine/designsystem-ui-react";
import { MouseEvent, PropsWithChildren } from "react";
import { navigate } from "wouter/use-browser-location";

import { sendButtonClickTracking } from "../common/tracking";

const Button = ({
   size = "compact",
   iconPosition = "right",
   className = "",
   onClick,
   to,
   trackingEvent,
   children,
   ...rest
}: PropsWithChildren<ButtonProps> & { trackingEvent?: string }) => {
   const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
      !!trackingEvent && sendButtonClickTracking(trackingEvent);
      if (to && !to.startsWith("http")) {
         navigate(to);
         e.preventDefault();
      }
      onClick && onClick(e);
   };
   return (
      <TineButton size={size} iconPosition={iconPosition} className={className} onClick={handleClick} to={to} {...rest}>
         {children}
      </TineButton>
   );
};

export default view(Button);
