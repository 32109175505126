import { view } from "@risingstack/react-easy-state";
import { Alert } from "@tine/designsystem-ui-react";

const ResetSuccess = () => (
   <Alert variant="success" showCloseButton={false}>
      Passordet ditt har blitt oppdatert. Du kan nå prøve å logge inn med nytt passord.
   </Alert>
);

export default view(ResetSuccess);
