import { view } from "@risingstack/react-easy-state";
import { Alert } from "@tine/designsystem-ui-react";
import isNil from "lodash-es/isNil";

import { Product } from "../common/types/productTypes";

import productStore from "../stores/product/productStore";

type LeadTimeWarningProps = {
   sku?: string;
   product?: Product;
   className?: string;
};

const LeadTimeWarning = ({ sku, product, className = "" }: LeadTimeWarningProps) => {
   const productData = product ? product : productStore.resolveSku(sku);
   if (productData === undefined) {
      return null;
   }
   const hasLeadTime = !isNil(productData.leadTime);
   const isMiniTank = productData.miniTank;
   if (!hasLeadTime && !isMiniTank) {
      return null;
   }

   const alertInfoText = isMiniTank
      ? "En minitank kan ikke reduseres i antall eller slettes etter innsending av ordre."
      : "Dette produktet er ikke mulig å levere på kort varsel.";
   return (
      <div className={className}>
         <Alert fullWidth variant="information" showCloseButton={false}>
            <p className="tw-m-0 tw-text-xs">{alertInfoText}</p>
            {hasLeadTime ? (
               <p className="tw-m-0 tw-text-xs">
                  Leveringstid: <strong>{productData.leadTime} dager</strong>
               </p>
            ) : null}
         </Alert>
      </div>
   );
};

export default view(LeadTimeWarning);
