import { view } from "@risingstack/react-easy-state";

import theme from "../../themes/theme";
import Button from "../Button";

const NewCustomerButton = () => {
   return (
      <Button size="regular" variant="tertiary" as="a" to={theme.newCustomerForm}>
         Bli kunde
      </Button>
   );
};

export default view(NewCustomerButton);
