import { view } from "@risingstack/react-easy-state";
import { IconExpandMore, IconLogout, IconUser } from "@tine/designsystem-icons/sharp";
import { Button } from "@tine/designsystem-ui-react";
import { cn } from "@tine/designsystem-utils";
import { MouseEventHandler, useRef, useState } from "react";
import { useLocation } from "wouter";

import useMediaQuery from "../../common/useMediaQuery";
import { mediaQueries } from "../../common/utils";
import Popover from "../../components/common/popover/Popover";

import authStore from "../../stores/auth/authStore";
import loginState from "../../stores/auth/loginState";
import featuresStore from "../../stores/features/featuresStore";
import notificationsStore from "../../stores/notifications/notificationsStore";

import NotificationsBadge from "../../account/notifications/NotificationsBadge";
import NavLink from "../../components/NavLink";
import MenuItems from "../MenuItems";

const listUnstyled = cn(["tw-list-none", "tw-pl-0"]);

const divideBase = "tw-border-0 tw-border-solid";
const divideBelow = cn(divideBase, "tw-border-b-[1px] tw-border-b-ink-border-subtle");
const divideAbove = cn(divideBase, "tw-border-t-[1px] tw-border-t-ink-border-subtle");
const flexStart = cn("tw-flex tw-justify-start");

const iconCN = cn("tw-ml-[-2.4px] tw-h-[24px] tw-w-[24px]");

const UserMenu = () => {
   const [_, redirectTo] = useLocation();
   const [show, setShow] = useState(false);
   const buttonRef = useRef<HTMLDivElement>(null);
   const isXl = useMediaQuery(mediaQueries.xl);

   const activateSwitchCompany = (e: React.MouseEvent) => {
      loginState.transitionTo("COMPANY_SELECTION");
      e.preventDefault();
   };

   const showSwitchCompany = authStore.companies && authStore.companies.length > 1 && !authStore.isPunchoutSession();

   const logoutAndRedirect: MouseEventHandler = () => {
      if (authStore.isPunchoutSession()) {
         loginState.transitionTo("NOT_LOGGED_IN");
         window.location.replace("/");
      }
      redirectTo("/");
      loginState.transitionTo("NOT_LOGGED_IN");
   };

   const handleClick = () => {
      setShow(!show);
   };

   const handleButtonKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
      if (show && e.key === "Tab" && !e.shiftKey) {
         const firstLink = document?.querySelector("#user-menu ul") as HTMLAnchorElement;
         console.log({ firstLink });
         firstLink?.focus();
      }
   };

   return (
      <>
         <div className="tw-flex">
            {featuresStore.notificationsEnabled && notificationsStore.notifications.data.length > 0 && (
               <div className="tw-z-10 -tw-mr-4">
                  <NotificationsBadge />
               </div>
            )}

            <div ref={buttonRef}>
               <Button
                  onKeyDown={handleButtonKeyDown}
                  onClick={handleClick}
                  aria-expanded={show}
                  aria-label="Min konto"
                  aria-controls="user-menu"
                  size="full"
                  iconPosition="left"
                  icon={<IconUser />}
                  variant="tertiary"
                  data-testid="user-menu-button"
               >
                  {useMediaQuery(mediaQueries.xl) && (
                     <div className="tw-flex tw-flex-row tw-items-center">
                        <span className="tw-text-nowrap">Min konto</span>
                        <span className="-tw-mb-[2px] tw-pl-1 tw-text-xs">({authStore.currentCompany})</span>
                        <IconExpandMore className="-tw-my-2 -tw-me-2 tw-h-6 tw-w-6" />
                     </div>
                  )}
               </Button>
            </div>
         </div>

         <Popover
            referenceElement={buttonRef.current}
            id="user-menu"
            show={show}
            onHide={() => setShow(false)}
            placement="bottom"
         >
            <ul
               className={cn([listUnstyled, "tw-mb-0 tw-px-0 tw-text-sm"])}
               tabIndex={0}
               onClick={() => {
                  buttonRef.current?.focus();
                  setShow(false);
               }}
            >
               {showSwitchCompany && (
                  <>
                     <li className={cn(divideBelow, "tw-px-4 tw-py-3")}>
                        <NavLink onClick={activateSwitchCompany}>Bytt firma</NavLink>
                     </li>
                  </>
               )}
               <MenuItems type="user" />
               <li className={cn(divideAbove, flexStart, "tw-px-4 tw-py-3")}>
                  <IconLogout className={iconCN} />
                  <NavLink onClick={logoutAndRedirect}>Logg ut</NavLink>
               </li>
            </ul>
         </Popover>
      </>
   );
};

export default view(UserMenu);
