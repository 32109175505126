import { view } from "@risingstack/react-easy-state";
import { useLocation } from "wouter";

import authStore from "../../../stores/auth/authStore";
import uiStore, { LOGIN_MODE } from "../../../stores/uiStore";

import Button from "../../Button";
import InputEmail from "./InputEmail";
import InputOtp from "./InputOtp";
import ResetSuccess from "./ResetSuccess";

type ResetPasswordProps = {
   title: string;
   showBackToLogInText?: boolean;
};

const ResetPassword = ({ title, showBackToLogInText = true }: ResetPasswordProps) => {
   const [, redirectTo] = useLocation();

   const { loginMode } = uiStore;

   const abortResetPassword = () => {
      uiStore.switchLoginMode(LOGIN_MODE.USERNAME);
      authStore.clearResetPassword();
      redirectTo("/");
   };

   const renderFormContent = () => {
      switch (loginMode) {
         case LOGIN_MODE.RESET_PASSWORD_EMAIL:
            return <InputEmail />;
         case LOGIN_MODE.RESET_PASSWORD_OTP:
            return <InputOtp />;
         case LOGIN_MODE.RESET_PASSWORD_SUCCESS:
            return <ResetSuccess />;
         default:
            return null;
      }
   };

   return (
      <div className="tw-flex tw-flex-col tw-gap-4">
         <h2>
            <b>{title}</b>
         </h2>
         {renderFormContent()}
         {loginMode !== LOGIN_MODE.RESET_PASSWORD_SUCCESS ? (
            <div className="tw-flex tw-items-center tw-gap-2">
               <hr className="login-divider" />
               <p className="tw-m-0 tw-text-center">Eller</p>
               <hr className="login-divider" />
            </div>
         ) : null}
         {showBackToLogInText || loginMode === LOGIN_MODE.RESET_PASSWORD_SUCCESS ? (
            <Button size="regular" variant="secondary" onClick={abortResetPassword}>
               Tilbake til innlogging
            </Button>
         ) : null}
      </div>
   );
};

export default view(ResetPassword);
