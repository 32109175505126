export type GAItemListInfo = {
   item_list_name?: string;
   item_list_id?: LIST_TYPE;
   item_list_length?: number;
   index?: number;
};

export type GAItemProduct = {
   item_id?: string;
   item_name?: string;
   item_brand?: string | null;
   item_category?: string | null;
   item_category2?: string | null;
   item_category3?: string | null;
   price?: number | null;
   unit_size?: string;
   stock_status?: string | undefined;
   callout?: string[];
};

export type GAItem = GAItemListInfo &
   GAItemProduct & {
      quantity?: number;
   };

type GAECommerceObject = {
   currency?: "NOK";
   coupon?: string | null;
   transaction_id?: string;
   value?: number | null;
   deliveryFee?: number;
   items?: GAItem[];
   num_items?: number;
};

export type GAAddtionalData = GAOrderEvent & {
   ecommerce?: GAECommerceObject & GAItemListInfo;
   creative_slot?: string;
   creative_name?: string;
   creative_type?: string;
   link_url?: string;
   items?: GAItem[];
   search_term?: string;
   search_type?: string;
   search_results?: number;
   filter_type?: string;
   filter_content?: string;
   method?: string;
   modal_name?: string;
   error_context?: string;
   error_text?: string;
   signup_name?: string;
   _clear?: boolean;
};

type GAOrderEvent = {
   order_type?: string;
   action?: string;
   order_interval?: number;
   order_class?: string;
};

export type GAListInformation = {
   type: LIST_TYPE;
   name: string;
   length: number;
};

export enum LIST_TYPE {
   CATEGORY = "category",
   CUSTOM = "custom",
   SEARCH = "search",
   CART = "cart",
   AVAILABILITY_CHECK = "availability_check",
   FAVORITES = "favorites",
   ORDER = "order",
   RELATED_PRODUCTS = "related_products",
   BARCODE = "barcode",
   RECOMMENDATIONS = "recommendations",
   CONTRACTUAL_PRODUCTS = "contractual_products",
   RECIPE = "recipe"
}

export type GAError = {
   context: string;
   text: string;
};
