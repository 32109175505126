import { view } from "@risingstack/react-easy-state";
import { IconClock, IconPen } from "@tine/designsystem-icons/sharp";
import { Group, Stack } from "@tine/designsystem-ui-react";
import isNil from "lodash-es/isNil";
import { useLocation } from "wouter";

import { ORDERTYPE } from "../common/types/productOrderTypes";
import { formatDate, formatDateTime, parseM3Date } from "../common/utils/dateUtils";

import cartStore from "../stores/cart/cartStore";

import Button from "../components/Button";
import InfoLine from "../components/InfoLine";
import { InternalLink } from "../components/Links";

const SubscriptionEditingDeliveryInfo = view(() => {
   const [_, redirectTo] = useLocation();
   if (isNil(cartStore.editing)) {
      return null;
   }

   const label = cartStore.editing.instanceDate
      ? "planlagt levering (" + formatDate(parseM3Date(cartStore.editing.instanceDate)) + "), abo. "
      : "abonnement";
   const { orderNumber } = cartStore.editing;
   const redirectUrl = cartStore.editing.deadline ? `/customer/orders/${orderNumber}` : `/customer/subscriptions/${orderNumber}`;
   return (
      <div className="tw-flex tw-w-full tw-flex-wrap tw-items-center tw-justify-between tw-gap-4 lg:tw-flex-row">
         <InternalLink href="/checkout/cart">
            Endrer {label} #{orderNumber}
         </InternalLink>
         <Button
            onClick={() => {
               cartStore.stopEditOrderMode().then(() => redirectTo(redirectUrl));
            }}
         >
            Avbryt endring
         </Button>

         <p className="tw-m-0 tw-text-sm">
            Endringene kan gjøres direkte i handlekurven. <br /> Oppdater abonnement for å lagre endringer.
         </p>
      </div>
   );
});

const DefaultEditingDeliveryInfo = view(() => {
   const [_, redirectTo] = useLocation();

   if (isNil(cartStore.editing)) {
      return null;
   }

   const { orderNumber } = cartStore.editing;

   return (
      <>
         <div className="tw-flex tw-w-full tw-flex-wrap tw-justify-between tw-gap-4 lg:tw-flex-row">
            <div className="tw-flex tw-items-center">
               <IconClock />
               <InfoLine
                  rowOnMobile
                  label="Endringsfrist"
                  align="start"
                  value={formatDateTime(cartStore.editing.deadline, true, true)}
               />
            </div>

            <Button
               onClick={() => {
                  cartStore.stopEditOrderMode().then(() => redirectTo(`/customer/orders/${orderNumber}`));
               }}
            >
               Avbryt endring
            </Button>

            <Button iconPosition="left" icon={<IconPen />} to="/checkout/cart" variant="tertiary">
               Endrer ordre #{cartStore.editing.orderNumber}
            </Button>
         </div>
         <div className="tw-flex">
            <p className="tw-m-0 tw-text-sm">
               Endringene kan gjøres direkte i handlekurven. Gå til kassen og send inn ordren for å lagre endringene.
            </p>
         </div>
      </>
   );
});

// Separate top bar when we are editing an order. TypeScript is a silly goose and doesn't understand that the existing check
// ensures the same thing as this added section section. To keep it easily humanly readable I am leaving both.
const ChangeOrderOrSubscription = () => {
   if (cartStore.isEditing() && !isNil(cartStore.editing)) {
      return (
         <Group justify="center" className="tw-bg-surface-default tw-shadow-md">
            <Stack className="tw-w-full tw-max-w-[--main-column-max-width] tw-px-3 tw-py-6 md:tw-px-12">
               {cartStore.editing.orderType === ORDERTYPE.WAS && <SubscriptionEditingDeliveryInfo />}
               {cartStore.editing.orderType !== ORDERTYPE.WAS && <DefaultEditingDeliveryInfo />}
            </Stack>
         </Group>
      );
   } else {
      return null;
   }
};

export default view(ChangeOrderOrSubscription);
