import { view } from "@risingstack/react-easy-state";
import { IconChevronLeft, IconChevronRight, IconMenu } from "@tine/designsystem-icons/sharp";
import { Button } from "@tine/designsystem-ui-react";
import { cn } from "@tine/designsystem-utils";
import isNil from "lodash-es/isNil";
import { Link, useLocation } from "wouter";

import { CategoryInfo } from "../../common/types/categoryTypes";
import { titleCase } from "../../common/utils";
import SlideFromSide from "../../components/common/slidefromside/SlideFromSide";

import authStore from "../../stores/auth/authStore";
import loginState from "../../stores/auth/loginState";
import categoryStore from "../../stores/categories/categoryStore";
import contentStore from "../../stores/cms/contentStore";

import Loading from "../../components/Loading";
import theme from "../../themes/theme";
import MenuItems from "../MenuItems";

const hasChildren = (cat: CategoryInfo) => !isNil(cat["children"]) && cat.children.length > 0;

const menuBodyClassName = cn(["tw-p-4, tw-min-w-[70vw]"]);

const drillDownTree = (menuItems: CategoryInfo[], path: string[]): CategoryInfo | undefined => {
   // Make sure this doesn't crash if called without data
   if (path.length === 0) {
      return undefined;
   }

   const [lookForKey, ...rest] = path;
   const foundItem = menuItems.find((mi) => mi.url_key === lookForKey);

   // No more path to process, we found the item.
   if (rest.length === 0) {
      return foundItem;
   }

   if (isNil(foundItem)) {
      return undefined;
   }

   return drillDownTree(foundItem.children, rest);
};

const itemCN = cn("tw-font-bold tw-mb-4 tw-cursor-pointer");

const RootMenu = view(() => {
   const path = categoryStore.mobile.path;

   return (
      <ul className="tw-list-none tw-pl-0">
         {categoryStore.categories.map((cat) => (
            <li className={itemCN} key={cat.id} onClick={() => path.push(cat.url_key)}>
               {titleCase(cat.name)} {hasChildren(cat) && <IconChevronRight className="tw-mr-4" />}
            </li>
         ))}
         {contentStore.mainMenuItems.map((menuItem) => (
            <li className={itemCN} key={menuItem.id}>
               <Link to={menuItem.url} onClick={() => categoryStore.showMenu(false)}>
                  {titleCase(menuItem.title)}
               </Link>
            </li>
         ))}
      </ul>
   );
});

const SubMenu = view(() => {
   const path = categoryStore.mobile.path;
   const selectedItem = drillDownTree(categoryStore.categories, path);
   const [_, redirectTo] = useLocation();

   const handleClick = (cat: CategoryInfo) => {
      if (!hasChildren(cat)) {
         categoryStore.showMenu(false);
         redirectTo(cat.fullUrl);
      } else {
         path.push(cat.url_key);
      }
   };

   const goBack = () => {
      categoryStore.mobile.path.pop();
   };

   if (isNil(selectedItem)) {
      return <div>Det oppsto den feil under lasting av kategoriene.</div>;
   }

   const visibleItems = selectedItem.children.filter((c: CategoryInfo) => c.visible);

   return (
      <div>
         <h2 className="tw-flex tw-items-center tw-mb-4 tw-cursor-pointer" onClick={goBack}>
            <IconChevronLeft className="tw-mr-4" /> {titleCase(selectedItem.name)}
         </h2>
         <Link
            className="tw-underline tw-cursor-pointer"
            onClick={() => categoryStore.showMenu(false)}
            to={"/" + path.join("/") + ".html"}
         >
            Se alle produkter i {titleCase(selectedItem.name)}
         </Link>

         <ul className="tw-list-none tw-pl-0 tw-mt-4">
            {visibleItems.map((cat: CategoryInfo) => (
               <li
                  className="tw-font-bold tw-mb-4 tw-flex tw-items-center tw-justify-between tw-cursor-pointer"
                  key={cat.id}
                  onClick={() => handleClick(cat)}
               >
                  {titleCase(cat.name)} {hasChildren(cat) && <IconChevronRight />}
               </li>
            ))}
         </ul>
      </div>
   );
});

const MobileCategories = () => {
   const [_, redirectTo] = useLocation();
   const showSwitchCompany = authStore.companies && authStore.companies.length > 1;

   if (categoryStore.categories.length === 0) {
      return <Loading />;
   }

   const hideMenu = () => {
      categoryStore.showMenu(false);
   };

   const activateSwitchCompany = (e: React.MouseEvent) => {
      loginState.transitionTo("COMPANY_SELECTION");
      e.preventDefault();
   };

   const mobileMenuHeader = () => (
      <img alt="Logo" width={56} height={Math.round(56 * theme.logoRatio)} src={theme.desktopLogoUrl} />
   );
   return (
      <>
         <Button variant="tertiary" icon={<IconMenu />} onClick={() => categoryStore.showMenu(true)} />
         <SlideFromSide renderHeader={mobileMenuHeader} show={categoryStore.mobile.visible} onHide={() => hideMenu()}>
            <div className={menuBodyClassName}>
               {categoryStore.mobile.path.length === 0 ? <RootMenu /> : <SubMenu />}
               { authStore.isLoggedIn() && 
               <><hr />
               <ul className="tw-list-none tw-pl-0">
                  <MenuItems type="mobile" />
               </ul></>}
               {showSwitchCompany && (
                  <>
                     <hr />
                     <a href="#" onClick={activateSwitchCompany}>
                        Bytt firma
                     </a>
                  </>
               )}
               {authStore.isLoggedIn() && (
                  <>
                     <hr />
                     <div className="tw-text-sm">
                        Logget inn som
                        <br />
                        {authStore.currentCompany} {authStore.getCurrentCompany()?.name ?? ""}
                     </div>
                  </>
               )}
            </div>
         </SlideFromSide>
      </>
   );
};

export default view(MobileCategories);
