import { view } from "@risingstack/react-easy-state";

import { formatPrice } from "../../common/utils";

import cartStore from "../../stores/cart/cartStore";
import deliveryFeeStore from "../../stores/deliveryFeeStore";
import promotionStore from "../../stores/promotionStore";

import Button from "../../components/Button";
import theme from "../../themes/theme";

const MiniCartGoToCart = () => {
   const cartSummaryBeforeDiscount = cartStore.getCartTotalBeforeDiscount();
   const cartSummary = cartStore.getCartTotal();
   const deliveryCharge = deliveryFeeStore.currentDeliveryCharge();
   const totalCartSum =
      cartSummary.total +
      cartSummary.recyclingCharge +
      (deliveryCharge?.currentFee?.fee ?? 0) -
      promotionStore.getTotalPromotionDiscountForCart();

   const discountFromM3Promotions = cartSummaryBeforeDiscount.total - cartSummary.total;
   const totalCartDiscount = promotionStore.getTotalPromotionDiscountForCart() + discountFromM3Promotions;

   return (
      <div className="tw-mb-2 tw-w-full tw-border-t tw-border-t-ink-border-subtle tw-px-1 lg:tw-px-4">
         <div className="tw--flex tw-justify-between tw-p-2">
            <div>Total sum</div>
            <div className="tw-font-bold">{formatPrice(totalCartSum)}</div>
         </div>
         {cartSummary.recyclingCharge > 0 && (
            <div className="tw-flex tw-justify-between tw-px-2 tw-text-sm">
               <div>Inkludert pant</div>
               <div>{formatPrice(cartSummary.recyclingCharge)}</div>
            </div>
         )}
         {theme.deliveryFee.enabled && (
            <div className="tw-mb-4 tw-mt-1 tw-flex tw-justify-between tw-px-2 tw-text-sm">
               <div>Inkludert ordregebyr</div>
               <div>{formatPrice(deliveryCharge?.currentFee?.fee)}</div>
            </div>
         )}
         {totalCartDiscount > 0 && (
            <div className="tw-mb-4 tw-mt-1 tw-flex tw-justify-between tw-px-2 tw-text-sm tw-text-promotion">
               <div>Inkludert rabatt</div>
               <div>{`- ${formatPrice(totalCartDiscount)}`}</div>
            </div>
         )}
         <div className="tw-flex tw-justify-center tw-p-2">
            <Button
               to="/checkout/cart"
               onClick={cartStore.hideMiniCart}
               className="tw-w-full"
               data-testid="go-to-cart-button"
               size="full"
            >
               Gå til handlekurv
            </Button>
         </div>
      </div>
   );
};

export default view(MiniCartGoToCart);
