import { view } from "@risingstack/react-easy-state";
import { Alert } from "@tine/designsystem-ui-react";
import { cn } from "@tine/designsystem-utils";
import { Entry } from "contentful";
import isUndefined from "lodash-es/isUndefined";
import { ImgHTMLAttributes, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { ImageWithFocusData } from "../../common/types/cmsTypes";
import { TypeImageWithFocusSkeleton } from "../../common/types/contentful";
import { AspectRatioType, ImagePlacementType } from "../../common/types/mediaTypes";
import {
   createCloudinaryUrl,
   createSourceSet,
   fromContentToImageWithFocus,
   generateSizesFromMaxSize
} from "../../common/utils/imageUtils";

import contentStore from "../../stores/cms/contentStore";

import theme from "../../themes/theme";

type ImageWithFocusProps = Omit<ImgHTMLAttributes<HTMLImageElement>, "sizes"> & {
   entryId: string | undefined;
   placement: ImagePlacementType;
   ratio: AspectRatioType;
   width: number;
   imageFromParent?: Entry<TypeImageWithFocusSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>;
};

/**
 * ImageWithFocus is a component that renders an image with focus point.
 *
 * It uses the Contentful API to fetch the image data, and then renders the image using the Cloudinary API.
 *
 * The image is rendered using the &lt;picture&gt; tag, with <source> tags for each media query.
 * The &lt;img&gt; tag is also rendered last, and is used as a fallback for browsers that do not
 * support <picture>. The loading attribute on &lt;img&gt; tag is also used to specify the loading strategy for the image.
 *
 * &lt;img&gt; is the tag actually being displayed in the browser. For more information about how <picture>
 * works, see https://stackoverflow.com/a/60405256
 *
 * @param entryId The Contentful Entry ID for the ImageWithFocus content
 * @param width The width of the image on desktop, full width is typically 1250px
 * @param ratio The aspect ratio of the image, 16x9, 21x9 or 32x9
 * @param placement Whether the image is likely to be above or below the fold in the current layout, used to determine loading strategy
 * @param className Additional classes to apply to the image
 * @constructor
 */
const ImageWithFocus = ({ entryId, width, ratio, placement, className, imageFromParent, ...rest }: ImageWithFocusProps) => {
   const [image, setImage] = useState<ImageWithFocusData | undefined>();

   const imageClassName = cn(className, "tw-size-full tw-rounded-md");

   if (isUndefined(entryId)) {
      console.warn("Attempting to insert ImageWithFocus with undefined entryId");
      return null;
   }

   const height =
      ratio === "32x9"
         ? Math.round(((width ?? 0) * 9) / 32)
         : ratio === "16x9"
           ? Math.round(((width ?? 0) * 9) / 16)
           : Math.round(((width ?? 0) * 9) / 21);
   const preferredLoadingStrategy = placement === "aboveFold" ? "eager" : "lazy";

   useEffect(() => {
      const fetchImage = async () => {
         if (imageFromParent && imageFromParent.fields && contentStore.isPreviewMode) {
            const image = fromContentToImageWithFocus(imageFromParent);
            setImage(image);
         } else {
            const imageWithFocus = await contentStore.getImageWithFocusUsingCache(entryId);
            setImage(imageWithFocus);
         }
      };
      void fetchImage();
   }, [entryId]);

   if (contentStore.isPreviewMode && isUndefined(image)) {
      return (
         <Alert variant="information" showCloseButton={false}>
            Bildet dukker opp når alle feltene på bildet er fylt ut
         </Alert>
      );
   }

   if (isUndefined(image) || isUndefined(image.originalAssetUrl)) {
      // Image data not fetched from Contentful yet...
      return (
         <div className={`ratio ratio-${ratio}`}>
            <Skeleton width={width} height={height} />
         </div>
      );
   }

   const sizes = generateSizesFromMaxSize(width, height);

   const urlFactory = (width: number, height: number) =>
      createCloudinaryUrl(image, width, height, theme.contentImages.cloudinaryMapping) || "";
   const sourceSetItems = createSourceSet(sizes, urlFactory);
   const itemsForSourceTags = sourceSetItems.filter((sourceSetItem) => sourceSetItem.media.length > 0);
   const itemForImgTag = sourceSetItems.find((sourceSetItem) => sourceSetItem.media.length === 0);

   return (
      <div>
         <picture>
            {itemsForSourceTags.map((sourceSetItem) => (
               <source
                  key={sourceSetItem.media + sourceSetItem.width}
                  srcSet={sourceSetItem.url}
                  media={sourceSetItem.media}
                  width={sourceSetItem.width}
                  height={sourceSetItem.height}
               />
            ))}
            {itemForImgTag && (
               <img
                  {...rest}
                  className={imageClassName}
                  alt={image.altText}
                  src={itemForImgTag.url}
                  width={itemForImgTag.width}
                  height={itemForImgTag.height}
                  loading={preferredLoadingStrategy}
               />
            )}
         </picture>
      </div>
   );
};

export default view(ImageWithFocus);
