import isNil from "lodash-es/isNil";

import { BusinessLevels, Chain, ChainLevel, Company, EnhancedCompany } from "../../common/types/companyTypes";
import { keepIdOnly } from "../../common/utils/segmentUtils";

export const enhanceCompany = (company: Company): EnhancedCompany => {
   return { ...company, chains: businessLevelsToChains(company.businessLevels || {}) };
};

const chainLevels: ChainLevel[] = ["level1", "level2", "level3", "level4"];
const businessLevelsToChains = (levels: BusinessLevels): Chain[] => {
   const chains: Chain[] = [];
   chainLevels.forEach((chainLevel) => {
      if (levels[chainLevel]) {
         const chain = businessLevelToChain(levels[chainLevel], chainLevel);
         if (chain) {
            chains.push(chain);
         }
      }
   });
   return chains;
};

const businessLevelToChain = (businessLevel: string, level: ChainLevel): Chain | undefined => {
   const [id, name] = businessLevel.split("|");
   if (id && name) {
      return {
         level,
         id,
         name: name.replace(/\s+NIVÅ\s+\d+$/i, "")
      };
   }
};

// Returns empty string if company or warehouse is undefined, otherwise returns the warehouse id
export const getWarehouseId = (company: EnhancedCompany | undefined): string => {
   if (isNil(company) || isNil(company.warehouse)) {
      return "";
   }
   return keepIdOnly(company.warehouse);
};
