import { view } from "@risingstack/react-easy-state";
import { cn } from "@tine/designsystem-utils";
import { HTMLProps } from "react";

import notificationsStore from "../../stores/notifications/notificationsStore";

interface NotificationBadgeProps extends HTMLProps<HTMLDivElement> {}
const notificationBadgeStyle = cn(
   "tw-bg-notification-bg tw-text-notification-text",
   "tw-size-5 tw-rounded-full tw-text-center tw-align-middle tw-text-xs tw-leading-5"
);

const NotificationsBadge = (props: NotificationBadgeProps) => (
   <>
      {notificationsStore.notifications.data.length > 0 && (
         <div {...props} className={cn(props.className || "", notificationBadgeStyle)}>
            {notificationsStore.notifications.data.length}
         </div>
      )}
   </>
);

export default view(NotificationsBadge);
