import { SupportedLocaleType } from "./languageTypes";
import { Product } from "./productTypes";
import { RecipeData, RecipeType } from "./recipeTypes";

export type AlgoliaContentfulLinkField = {
   sys: {
      type: "Link";
      id: string;
   };
};

export type Localized<T> = { [locale in SupportedLocaleType]?: T };

export type AlgoliaV2_articleHit = {
   objectID: string;
   fields?: {
      headerImage?: Localized<AlgoliaContentfulLinkField>;
      title?: Localized<string>;
      callToAction?: Localized<string>;
      callToActionSubTitle?: Localized<string>;
   };
};

export type AlgoliaRecipeHit = {
   objectID: string;
   fields?: {
      recipeData?: Localized<RecipeType>;
      title?: Localized<string>;
   };
};
export type AlgoliaCmsContentHit = AlgoliaV2_articleHit | AlgoliaRecipeHit;

export type AlgoliaProductHit = {
   objectID: string;
   sku: string;
   name: string;
   url_key: string;
};

export type AlgoliaHit = AlgoliaProductHit | AlgoliaCmsContentHit;

export type AlgoliaEvent = {
   eventType: string;
   eventName: string;
   objectIDs: string[];
   index: string;
   userToken?: string;
   timestamp: number;
   queryID?: string;
   positions?: number[];
};

export const enum SEARCH_TYPE {
   QuickSearch = "quick",
   Full = "full"
}

export type SearchData = {
   results: SearchResults[];
   resultsFor: string;
};

export type AlgoliaRequestOptions = {
   filters: string;
   hitsPerPage: number;
   clickAnalytics: boolean;
   userToken?: string;
   restrictSearchableAttributes?: string[];
};

export type SearchDomainType = "product" | "v2_article" | "recipe";
interface BaseSearchResults<HitType extends AlgoliaHit> {
   name: SearchDomainNameType;
   queryID: string;
   hits: HitType[];
   query: string;
}
interface TypedSearchResults<T extends SearchDomainType, HitType extends AlgoliaHit> extends BaseSearchResults<HitType> {
   searchDomainType: T;
}

export type ProductSearchResults = TypedSearchResults<"product", AlgoliaProductHit> & {
   products?: Product[];
};

export type CallToActionData = {
   id: string;
   imageId: string;
   callToAction: string;
   subTitle?: string;
};
export type V2ArticleSearchResults = TypedSearchResults<"v2_article", AlgoliaCmsContentHit> & {
   data: CallToActionData[];
};

export type RecipeSearchResults = TypedSearchResults<"recipe", AlgoliaRecipeHit> & {
   data: RecipeData[];
};

export type CmsContentSearchResults = V2ArticleSearchResults | RecipeSearchResults;

export type SearchResults = ProductSearchResults | V2ArticleSearchResults | RecipeSearchResults;

export type SearchDomainNameType =
   | "produkter"
   | "products"
   | "inspirasjon"
   | "inspiration"
   | "oppskrifter"
   | "recipes"
   | "artikler"
   | "articles";

export type SearchDomain = {
   name: SearchDomainNameType;
   type: SearchDomainType;
   homePath: { (locale: SupportedLocaleType): string };
   displayName: { (locale: SupportedLocaleType): string };
   linkText: { (locale: SupportedLocaleType): string };
   indexName: string;
};

export type AllSearchTypes = SEARCH_TYPE | "direct";

type AlgoliaConfig = {
   appId: string;
   searchApiKey: string;
};

export type SearchConfig = {
   searchDomains: SearchDomain[];
   algoliaConfig: AlgoliaConfig;
};

export type AlgoliaResultCallbacks = {
   addToCart?: (result: AlgoliaHit, listname: string, position: number) => void;
   click?: (result: AlgoliaHit, listname: string, position: number) => void;
   impression?: (result: AlgoliaHit, listname: string, position: number) => void;
};

export type SearchComponentProps = {
   searchDomain: SearchDomain;
   maxHits?: number;
   isNarrow?: boolean;
};
